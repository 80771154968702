"use strict";
$(
    function() {
        app.fonts.init();
    }
);
app.fonts = {
    _vars: {
        sybmolFonts: {
            FontAwesome: 'FontAwesome',
            MaterialIcons: 'MaterialIcons',
            Linearicons: 'Linearicons'
        }
    },
    init: function() {
        app.fonts.fontLineariconsExists();
        app.fonts.fontMaterialIconsExists();
        app.fonts.fontAwesomeExists();
    },
    fontAwesomeExists: function() {
        return this.fontExists('FontAwesome');
    },
    fontLineariconsExists: function() {
        return this.fontExists('Linearicons');
    },
    fontMaterialIconsExists: function() {
        return this.fontExists('MaterialIcons');
    },
    fontExists: function(fontName) {
        if (app.fonts._vars.sybmolFonts[fontName]) {
            if (app._vars._customFonts && app._vars._customFonts[fontName]) {
                return true;
            } else {
                return false;
            }
        }

        var fonts = app.getLData('udu__checkedFonts', true);
        if (!fonts) {
            fonts = {};
        }

        if (fonts[fontName] === 'Y') {
            return true;
        } else if (fonts[fontName] === 'N') {
            return false;
        }

        var fontExists = false;
        if (app._vars._customFonts && app._vars._customFonts[fontName]) {
            fontExists = true;
        } else {
            try {
                fontExists = document.fonts.check("12px " + fontName);
            } catch(e) {
                var d = new app.fonts.Detector();
                fontExists = d.detect(fontName);
            }
        }

        if (!fontExists) {
            return false;
        } else {
            if (app.fonts._vars.sybmolFonts[fontName]) {
                for (var i in app.fonts._vars.sybmolFonts) {
                    fonts[i] = 'N';
                }
            }
            fonts[fontName] = 'Y';
            app.setLData('udu__checkedFonts', fonts, true);
            return true;
        }
    },
    Detector: function() {
        // a font will be compared against all the three default fonts.
        // and if it doesn't match all 3 then that font is not available.
        var baseFonts = ['monospace', 'sans-serif', 'serif'];

        //we use m or w because these two characters take up the maximum width.
        // And we use a LLi so that the same matching fonts can get separated
        var testString = "mmmmmmmmmmlli";

        //we test using 72px font size, we may use any size. I guess larger the better.
        var testSize = '72px';

        var h = document.getElementsByTagName("body")[0];

        // create a SPAN in the document to get the width of the text we use to test
        var s = document.createElement("span");
        s.style.fontSize = testSize;
        s.innerHTML = testString;
        var defaultWidth = {};
        var defaultHeight = {};
        for (var index in baseFonts) {
            //get the default width for the three base fonts
            s.style.fontFamily = baseFonts[index];
            h.appendChild(s);
            defaultWidth[baseFonts[index]] = s.offsetWidth; //width for the default font
            defaultHeight[baseFonts[index]] = s.offsetHeight; //height for the defualt font
            h.removeChild(s);
        }

        function detect(font) {
            var detected = false;
            for (var index in baseFonts) {
                s.style.fontFamily = font + ',' + baseFonts[index]; // name of the font along with the base font for fallback.
                h.appendChild(s);
                var matched = (s.offsetWidth != defaultWidth[baseFonts[index]] || s.offsetHeight != defaultHeight[baseFonts[index]]);
                h.removeChild(s);
                detected = detected || matched;
            }
            return detected;
        }

        this.detect = detect;
    }

};