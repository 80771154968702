"use strict";
app.prodDetailUtils = {
	_vars: {
		v: 1
	},
    moveCommitButtonTo: function(sel, forceAdd) {
        if ($('.mult-quantity').length > 0 && !forceAdd) {
            return false;
        }
        if (!sel) {
            sel = '.price-table.udu__scroller-1';
        }
        var $toDiv = $(sel);
        var $div = $('form div.submit');
        var $frm = $div.closest('form');

        $('table.price-table').wrap('<div class="udu__price-table-wrapper udu__new-submit-place"></div>');

        $toDiv.append('<div class="udu__submit-div-wrapper udu__new-submit-place"></div>');

        $toDiv.addClass('udu__with-submit-button')
            .find('.udu__submit-div-wrapper')
            .append($div);

        $toDiv.after('<div class="udu__inventory-wrapper"></div>');

        var $prodInventory = $('.product-inventory');
        var $udu_availableQntZeroMessage = $('#udu_availableQntZeroMessage');
        $('.udu__inventory-wrapper')
            .append($udu_availableQntZeroMessage)
            .append($prodInventory);

        $frm.append('<input type="hidden" id="pr_form_id_quantity-copy" name="quantity">');

        $('.udu__submit-div-wrapper').find('button.fa-cart').click(
            function(e) {
                var $inp = $('#pr_form_id_quantity-copy');
                $inp.val($('#pr_form_id_quantity').val());
                $inp.closest('form').submit();

                e.preventDefault();
            }
        )
    },
    selectLeftMenuCats: function() {
        if (!app.prodInfo) {
            return false;
        }
        var cats = app.prodInfo.cats.split(',');
        for (var i = 0; i < cats.length; i++) {
            var catId = cats[i];
            var $a = $('.li-block a#ucat-' + catId);
            if ($a.length === 1) {
                $a.addClass('selected').closest('li').addClass('selected');
                break;
            }
        }
    },
	buildRecentProducts: function() {
		var saveKey = 'udu-lastSeenProducts:' + app.storeId;
		var savedData = app.getLData(saveKey);

		if (!savedData) {
			return false;
		}

		var html = '<div class="li-block resent-seen-prods shown n-pop">';
		html += '<h3>' + app.lang.selectWord('RECENTLY VIEWED', 'Récemment consulté') + '</h3>';
		html += '<div class="seen-wrapper li-wrapper">';
		var limit = 4;
		for (var i = 0; i < limit; i++) {
			var info = savedData[i];
			if (!info) {
				break;
			}
			if (String(info['img']).indexOf('/') < 0) {
				limit++;
				continue;
			}
			html += '<div class="item">';
			html += '<div class="image"><a href="' + info['url'] + '"><img src="' + info['img'] + '" alt="' + app.utils.str.stripTags(info['name']) + '"></a></div>';
			html += '<div class="name"><a href="' + info['url'] + '">' + app.utils.str.stripTags(info['name']) + '</a></div>';
			if (info['price'] && info['price'] !== 'undefined') {
				html += '<div class="price">' + info['price'] + '</div>';
			} else {
				html += '<div class="price"></div>';
			}

			html += '</div>';
		}
		html += '</div></div>';
		document.write(html);
	},
	gstKey: function(prodId, cur, cnt, elems) {
		if (!elems) {
			elems = [];
		}
		return app.utils.str.md5('gst-' + prodId + '_' + app._vars.userId + '-' + cur + '_' + cnt + '_' + JSON.stringify(elems), 6);
	},
	_addGstItem: function(i, info, resArr, readArr, savedData, force) {
		if (info.items) {
			for (var a = 0; a < info.items.length; a++) {
				app.prodDetailUtils._addGstItem(i, info.items[a], resArr, readArr, savedData, force);
			}
		} else {
			info.id = i;
			var k = app.prodDetailUtils.gstKey(i, app.money.getCurCurrency(), info.cnt, info.elems);
			if (!savedData[k] || force) {
				info.k = k;
				readArr.push(info);
			} else {
				var r = info.id;
				if (resArr[r]) {
					r = r + '_' + k;
				}
				resArr[r] = {
					data: info,
					res: savedData[k],
					k: k
				}
			}
		}
	},
	gstPrice: function(items, callBack, force) {
		if (!app._usrLogged || !items) {
			callBack({});
			return true;
		}

		app.url.getRestUrl(
			function(restUrl) {
				restUrl += 'store/products/price-estimate';

				var savedData = app.getLData('_uduGst6', true);
				if (!savedData || window.admin) {
					savedData = {

					}
				}
				var readArr = [

				];

				var resArr = {

				};

				for (var i in items) {
					app.prodDetailUtils._addGstItem(i, items[i], resArr, readArr, savedData, force);
				}

				if (readArr.length === 0) {
					callBack(resArr);
					return resArr;
				}

				var urlArr = [];
				for (i = 0; i < readArr.length; i++) {
					var info = readArr[i];
					urlArr.push({
						name: 'products[' + i + '][id]',
						val: info.id
					});
					urlArr.push({
						name: 'products[' + i + '][quantity]',
						val: info.cnt || 1
					});

					if (info.elems) {
						for (var a = 0; a < info.elems.length; a++){
							urlArr.push({
								name: 'products[' + i + '][elementIds][]',
								val: info.elems[a]
							});
						}
					}
				}

				var url = app.url.formURL(restUrl, urlArr);

				$.ajax({
					url: url,
					complete: function(res) {
						var data = res.responseJSON;

						var savedData = app.getLData('_uduGst6', true);
						if (!savedData) {
							savedData = {};
						}

						for (var i = 0; i < data.length; i++) {
							var info = readArr[i];

							var k = info.k;
							var r = info.id + '_' + k;
							savedData[k] = data[i];

							if (resArr[info.id]) {
								resArr[r] = {
									data: info,
									res: savedData[k],
									k: k
								}
							} else {
								resArr[info.id] = {
									data: info,
									res: savedData[k],
									k: k
								}
							}
						}

						callBack(resArr);
						app.setLData('_uduGst6', savedData, true);
					}
				});
			}
		)
	}
};
