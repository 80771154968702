"use strict";
$(
    function() {
        app.cart.init();
    }
);
app.cart = {
    _vars: {

    },
    init: function() {
        app.cart.assignCartPopup();
        app.cart.setGstPrice();
    },
    assignCartPopup: function() {
        var $item = $('.udu__cart-popup-place');
        if ($item.length === 0 || $item.hasClass('_done')) {
            return false;
        }

        app.balloon.assignStableBalloon (
            '.udu__cart-popup-place',
            '<div class="udu__my-cart-popup my-cart-popup udu__loading-v1"></div>',
            {side:['bottom'], isWhiteMode: true, balloonClassName: 'udu__under-confirm-mode'}
        );

        $item.mouseover(
            function() {
                app.cart._updateCartInfo($item);
            }
        );
    },
	getIdByHref: function(href) {
		var num = String(href).match(/(\d+)\.html/);
		if (!num) {
			return null;
		}
		return num[1];
	},
	setGstPrice: function() {
		if (!app._usrLogged) {
			return this;
		}
		var $places = $('table.list .udu__gst-place:not(._done), .udu-print__prod-item .udu__gst-place:not(._done)').addClass('_done');

		if ($places.length === 0) {
			return this;
		}

		var arr = {};

		$places.each(
			function() {
				var guid = app.utils.str.md5(app.guid(), 6);
				var $this = $(this);
				var $tr = $this.closest('tr, .udu-print__prod-item').attr('data-gst-guid', guid);
				var href = $tr.find('a.prod-name').attr('href');
				var prodId = app.cart.getIdByHref(href);
				if (!prodId) {
					prodId = $tr.data('prod-id');
				}

				if (!arr[prodId]) {
					arr[prodId] = {
						items: []
					}
				}

				var elems = [];
				if ($tr.hasClass('udu-print__prod-item')) {
					var elIds= $tr.data('elem-ids');
					if (elIds) {
						var tmpIds = String(elIds).split(',');
						for (let i = 0; i < tmpIds.length; i++) {
							if (Number(tmpIds[i])) {
								elems.push(tmpIds[i]);
							}
						}
					}
				} else {
					$tr.find('.options li[data-elem-id]').each(
						function() {
							elems.push($(this).attr('data-elem-id'));
						}
					);
				}


				var cnt = $tr.find('input[name=quantity]').val();
				if (!cnt) {
					cnt = $tr.find('.udu-print__prod-count').html();
				}
				if (!Number(cnt)) {
					cnt = 1;
				}

				arr[prodId].items.push({
					elems: elems,
					cnt: cnt,
					guid: guid
				})
			}
		);

		app.prodDetailUtils.gstPrice(
			arr,
			function(data) {
				var sum = 0;
				var sum2 = 0;
				for (var i in data) {

					var info = data[i];
					var guid = info.data.guid;
					var $tr = $('tr[data-gst-guid="' + guid + '"], div.udu-print__prod-item[data-gst-guid="' + guid + '"]');
					var $place = $tr.find('.udu__gst-place').removeClass('udu__loading udu__load-size-1');
					var price = '';
					if (info.res.price) {
						price = info.res.price;
					} else {
						price = app.money.parse($tr.find('.unit-price .money').html());
					}

					var minusPrice = $place.data('udu-price-minus');
					if (Number(minusPrice)) {
						price -= minusPrice;
					}

					if ($place.closest('td.udu__you-save, .udu-print__prod-price').length === 0) {
						sum += price * info.data.cnt;
					} else {
						sum2 += price * info.data.cnt;
					}

					$place.html('<span class="money">' + app.money.toDollar(price) + '</span>');
				}

				$('.udu__loading.udu__load-size-1').removeClass('udu__loading udu__load-size-1');
				$('.udu__gst-place-total').html(String(app.money.toDollar(sum)).replace(/,+/, ''));
				$('.udu__gst-on-save-total').html(String(app.money.toDollar(sum2)).replace(/,+/, ''));

				if (app.checkout) {
					app.checkout.fillProdJsonField();
				}

			}
		);
    },
    updateTopCart: function() {
        var $item = $('.udu__cart-popup-place');
        $item.removeClass('_done');
        app.cart._updateCartInfo($item);
    },
    _updateCartInfo: function($item) {
        if ($item.hasClass('_done')) {
			app.events.trigger(
				'udu_CART-POPUP-OPEN',
				{
					$item: $item
				}
			);

			app.cart._assignAdminEditor();

            return false;
        }
        $item.addClass('_done');
        app.getInfo(
            'get-cart',
            {

            },
            function(data) {
                var addClass = '';
                if (data.indexOf('udu__popup-cart-empty') > 0) {
                    addClass = ' empty-cart';
                }
                data = '<div class="udu__my-cart-popup my-cart-popup' + addClass + '">' +  data + '</div>';
                $item.data('udu-balloon-html', data);

                var $place = $('.udu__my-cart-popup').removeClass('udu__loading-v1');

                var tmp = data.match(/data-prod-cart-count="(\d+)"/);
                if (tmp) {
                    var prodCnt = tmp[1];
                } else {
                    prodCnt = 0;
                }
                if (prodCnt > 0) {
                    $('.empty-cart').removeClass('empty-cart');
                }
                $('.my-cart-count').html(prodCnt);

                tmp = data.match(/data-prod-cart-total=\"([^\"]+)\"/);
                if (tmp) {
                    $('.my-cart-total').html(tmp[1]);
                }

                app.balloon.show($item, data, {noDelay: true, onlyIfVisible: true, isWhiteMode: true, isStable: true, balloonClassName: 'udu__under-confirm-mode'});

                app.events.trigger(
                    'udu_CART-POPUP-OPEN',
                    {
						$item: $item
                    }
                );

                app.cart._assignAdminEditor();
            }
        );
    },

    _assignAdminEditor: function() {
		if (window.admin) {
			var $cartPopup = $(".udu__balloon-body .udu__my-cart-popup");
			if ($cartPopup.data('admin-assigned') != 'Y') {
				$cartPopup.data('admin-assigned', 'Y');
				setTimeout(
					function() {
						window.admin.assignEditor();
					},
					400
				)
			}
		};
    }
};