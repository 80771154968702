app.db = {
	_vars: {

	},
	get: function (k, callBack) {
		app.getInfo(
			'get-db-data',
			{
				tp: k
			},
			function(data) {
				try {
					data = JSON.parse(data);
					data['data'] = app.base64.decode(data['data']);
				} catch (e) {
					data = {
						data: null
					}
				}
				app.db._vars[k] = data['data'];
				if (callBack) {
					callBack(data['data']);
				}
			}
		)
	},
	set: function(k, data, callBack) {
		if (typeof data === 'object') {
			data = JSON.stringify(data);
		}
		app.getInfo(
			'set-db-data',
			{
				tp: k,
				data: data
			},
			function(data) {
				if (callBack) {
					callBack(data);
				}
			}
		)
	},
	setSessionData: function(sesVar, callBack) {
		var val = app.getLData(sesVar + app.db.usrMd5(), true);
		if (!val) {
			val = {};
		}
		app.db.set(
			sesVar,
			val,
			function(data) {
				if (callBack) {
					callBack(data);
				}
			}
		);
	},
	getJson: function(k, callBack, defVal) {
		app.db.get(
			k,
			function(data) {
				if (data) {
					try {
						data = JSON.parse(data);
					} catch (e) {
						data = {

						};
					}
				}
				if ($.isEmptyObject(data) && defVal) {
					data = defVal;
				}
				if (callBack) {
					callBack(data);
				}
			}
		)
	},
	getSessionData: function(sesVar, callBack, defVal) {
		var val = app.getLData(sesVar + app.db.usrMd5(), true);
		if (val) {
			if (callBack) {
				callBack(val);
			}
		} else {
			app.db.getJson(
				sesVar,
				function(data) {
					app.setLData(sesVar + app.db.usrMd5(), data, true);
					if (callBack) {
						callBack(data);
					}
				},
				defVal
			)
		}
	},
	usrMd5: function() {
		return '__' + app.utils.str.md5(app._usrId + '-XMAIN', 4);
	}
};