app.utils.scripts = {
	initQuickOrder: function(callBack) {

		app.getScript(
			['https://cdn.uducat.com/static/esb/js/app.quickOrder.js', 'https://cdn.uducat.com/static/esb/udu-css/css/app.quickOrder.css'],
			function() {
				if (callBack) {
					callBack();
				} else {
					app.quickOrder.initialize();
				}
			}
		)
	},
	initDefLocation: function(callBack) {
		app.getScript(
			[
				'https://cdn.uducat.com/static/esb/udu-css/css/style-def.loc.css',
				'https://cdn.uducat.com/static/esb/js/main.def.loc.js'],
			function() {
				if (callBack) {
					callBack();
				}
			}
		)
	},
	initWnd: function(callBack) {
		app.getScript(
			[
				'https://cdn.uducat.com/static/esb/js/app.wnd.js',
				'https://cdn.uducat.com/static/esb/udu-css/css/app.wnd.css'
			],
			callBack,
			{
				isLoaded: app.wnd
			}
		);
	},
	initZoomer: function(callBack) {
		app.getScript(
			['https://cdn.uducat.com/static/esb/js/app.zoomer.js', 'https://cdn.uducat.com/static/esb/js/jquery.ez-plus.js'],
			function() {
				if (callBack) {
					callBack();
				}
			}
		)
	},
	initLeftBlog: function() {
		app.utils.scripts.initOwlSlider(
			function() {
				app.owlSlider.makeLeftBlog();
			}
		)
	},
	initProdDetailMainSlider: function() {
		app.utils.scripts.initOwlSlider(
			function() {
				app.owlSlider.makeMainProdImageSlider()
			}
		);
	},
	initRatings: function (callBack) {
		app.getScript(
			[
				'https://cdn.uducat.com/static/esb/js/jquery.star-rating-svg.js',
				'https://cdn.uducat.com/static/esb/udu-css/css/star-rating-svg.css'
			],
			function() {
				if (callBack) {
					callBack();
				}
			}
		)
	},
	initOwlSlider: function(callBack) {
		if ($.fn.owlCarousel) {
			if (callBack) {
				callBack();
			}
		} else {
			app.getScript(
				['https://cdn.uducat.com/static/esb/js/main.owl.slider.js', '/js/owlSlider/owl-slider.css'],
				function() {
					if (callBack) {
						callBack();
					}
				}
			)
		}
	},
	initTree: function(callBack) {
		app.getScript(
			[
				'https://cdn.uducat.com/static/esb/js/app.tree.min.js',
				'https://cdn.uducat.com/static/esb/udu-css/css/app.tree.css'
			],
			function() {
				if (callBack) {
					callBack();
				}
			},
			{
				isLoaded: app.tree
			}
		)
	},
	iniJ2F: function(callBack) {
		app.getScript(
			[
				'https://cdn.uducat.com/static/esb/js/j2f.js',
				'https://cdn.uducat.com/static/esb/udu-css/css/j2f.css'
			],
			function() {
				if (callBack) {
					callBack();
				}
			},
			{
				isLoaded: app.j2f
			}
		);
	}
};