app.utils.fancy = {
	init: function(callBack) {
		app.getScript(
			'fancy',
			function() {
				if (callBack) {
					callBack();
				}
			}
		)
	},
	initDtl: function() {
		this.init(
			function() {
				$("div.product-view div.zoom a, ._ div.product-view .image-inner a, div.product-view div.zoom-advanced a, fieldset.options .option-image a").fancybox(
					{
						'transitionIn': 'elastic',
						'transitionOut': 'elastic'
					}
				);
			}
		)
	}
};