"use strict";
if (!app.utils) {
    app.utils = {};
}

$(
	function() {
		app.utils.images.setTinyEffects();
	}
);

app.utils.images = {
    _vars: {
        images: {

        }
    },
    load: function(src, callBack) {
        if (this._vars.images[src]) {
            var info = this._vars.images[src];
            callBack(info);
        } else {
            var img = new Image();
            img.onload = function() {
                var info = app.utils.images._vars.images[src] = {
                    w: img.width,
                    h: img.height,
                    k: img.width / img.height,
                    src: src
                };
                callBack(info);
            };
            img.src = src;
        }
    },
    getUploadFileSrc: function(e, callBack, asText) {
        var files = e.target.files;
        var f = files[0];
        var reader = new FileReader();
        reader.onload = (function(theFile) {
            return function(e) {
                if (callBack) {
                    callBack(e.target.result)
                }
            };
        })(f);
        if (asText) {
            reader.readAsText(f);
        } else {
            reader.readAsDataURL(f);
        }
    },
    src2Base64: function(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    },
    bgInfo: function($item) {
        var $item = $($item);
        if ($item.length === 0) {
            return null;
        }
        var res = {};
        var bgInfo = $item.css('background');
        var tmp = String(bgInfo).match(/url\(([^\)]+)\)/);
        res['bgInfo'] = bgInfo;
        if (tmp && tmp[1]) {
            res['img'] = tmp[1].replace(/\"/g, '');
            res['fileName'] = app.utils.file.baseName(res['img']);
            tmp = res['fileName'].match(/-(\d+)(\.|\-fr\.)png/);
            if (tmp && tmp[1]) {
                res['num'] = tmp[1];
            } else {
				res['num'] = 0;
            }
        } else {
            res['img'] = '';
            res['fileName'] = '';
            res['num'] = 0;
        }

        return res;
    },
	svg2png: function(svgString, callBack) {
		var canvas = document.createElement('canvas');
		try {
			tmp = svgString.match(/viewBox="([^\"]+)"/)[1].split(' ');
			var w = tmp[2];
			var h = tmp[3];
        } catch(e) {
		    w = 600;
		    h = 300;
        }
		canvas.width = w;
		canvas.height = h;
		var ctx = canvas.getContext("2d");
		var DOMURL = self.URL || self.webkitURL || self;
		var img = new Image();
		var svg = new Blob([svgString], {type: "image/svg+xml;charset=utf-8"});
		var url = DOMURL.createObjectURL(svg);
		img.onload = function() {
			ctx.drawImage(img, 0, 0);
			var png = canvas.toDataURL("image/png");
			$(canvas).remove();
			$(img).remove();
			callBack(png);
		};
		img.src = url;
	},
	setTinyEffects: function() {
		$('img[data-udu-tiny-alt-type]:not(._done-i3)')
			.addClass('_done-i3')
			.each(
				function() {
					var $this = $(this);
					var tp = $this.attr('data-udu-tiny-alt-type');
					var altUrl = $this.attr('data-alt-url');
					var iniUrl = $this.attr('src');

					$this.attr('data-ini-url', iniUrl);

					if (tp === 'MO') {
						$this.mouseenter(
							function() {
								$(this).attr('src', altUrl);
							}
						).mouseout(
							function() {
								$(this).attr('src', iniUrl);
							}
						)
					} else if (tp === 'ME') {
						$this.mouseenter(
							function() {
								$(this).attr('src', altUrl);
							}
						)
					} else if (tp === 'C') {
						$this.click(
							function() {
								$(this).attr('src', altUrl);
							}
						)
					} else if (tp === 'MD') {
						$this.mousedown(
							function() {
								$(this).attr('src', altUrl);
							}
						)
					}
				}
			)
	}
};