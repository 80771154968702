"use strict";

app.focuser = {
    _vars: {

    },
    focus: function($el) {
        $el = $($el);
        app.focuser.init();
        if ($el.length > 0) {
            $el.focus();
			app.focuser._vars.$lastNormalFocusedItem = $el;
        }
    },
    devMode: function(devMode) {
        app.focuser._vars.devMode = devMode;
        if (devMode) {
            $('.menu a, .menu li, ul').addClass('focus _devMode');
        } else {
            $('.menu a, .menu li, ul').removeClass('focus _devMode');
        }
    },
    getSkipLinksCollection: function() {
        var html = '<ul class="udu__skip-links-collection">';

        var arr = {
            '.d1': {
                cap: app.lang.selectWord('Main Content', 'Contenu principal'),
                id: 'main_content'
            },
            '.d2': {
                cap: app.lang.selectWord('Left Menu', 'Menu de gauche'),
                id: 'left_part'
            },
            '.footer': {
                cap: app.lang.selectWord('Go to Footer', 'Aller au pied de page'),
                id: 'footer'
            },
            '.product-list2 .item:first-child': {
                cap: app.lang.selectWord('Go to Product List', 'Aller à la liste des produits'),
                id: 'product-list2-list'
            },
            '.top .menu > ul': {
                cap: app.lang.selectWord('Go to Top Menu', 'Aller au menu supérieur'),
                id: 'udu-top-menu'
            },
            '#udu__quick-search': {
                cap: app.lang.selectWord('Go to Quick Search', 'Aller à la recherche rapide'),
                goToId: 'udu__quick-search'
            },
            '#slider': {
                cap: app.lang.selectWord('Main Banner', 'Bannière principale'),
                id: 'slider',
                nav: '.nivo-controlNav a.active'
            }
        };

        var idx = 100;
        for (var i in arr) {
            var info = arr[i];
            var $el = $(i);
            if ($el.length === 1) {
                if (info.id) {
                    $el.attr('id', info.id);
                    var nav = '';
                    if (info.nav) {
                        nav = 'data-udu-nav="' + info.nav + '"';
                    }
                    html += '<li><a ' + nav + ' data-tab-group="udu-wnd" data-tab-index="' + idx + '" href="#' + info.id + '">' + info.cap + '</a></li>'
                    idx += 10;
                } else if (info.goToId) {
                    html += '<li><a data-goto="' + info.goToId + '" data-tab-group="udu-wnd" data-tab-index="' + idx + '" href="#">' + info.cap + '</a></li>'
                    idx += 10;
                }
            }
        }

        html += '</ul>';
        return html;
    },
    openSkipLinks: function () {
        var $this = $('#udu__skipLinksButton').addClass('udu__shown-link');
        app.focuser._vars.$lastNormalFocusedItem = $this;
        if ($this.data('udu-link-opened') !== 'Y') {
            $this.data('udu-link-opened', 'Y');
            app.alert(app.focuser.getSkipLinksCollection(), null, {cssClass: 'fullMode'});

            $('.udu__skip-links-collection a').click(
                function() {
                    var $this = $(this);
                    var href = $this.attr('href');

                    if (href !== '#') {
                        var nav = $this.data('udu-nav');
                        if (!nav) {
                            app.focuser._vars.$lastNormalFocusedItem = $(href).find('a').focus();
                        } else {
                            app.focuser._vars.$lastNormalFocusedItem = $(nav).focus();
                        }

                    } else if ($this.data('goto')) {
                        var goToId = $this.data('goto');
                        app.focuser._vars.$lastNormalFocusedItem = $('#' + goToId);
                    }

                    $('#udu__skipLinksButton').removeClass('udu__shown-link').data('udu-link-opened', 'N');
                    app.hideWindow();
                }
            );

        } else {
            $this.data('udu-link-opened', 'N');
        }
    },
    init: function() {
        var $letters = $('.aclb-letters');
        if ($letters.length === 0) {
            return false;
        }
        if (!app.focuser._vars.initDone) {
            app.focuser._vars.initDone = true;

            $('a.ln-fr').attr('lang', 'fr');
            $('a.ln-en').attr('lang', 'en');

            $('.product-list2 .item a')
                .focus(
                    function() {
                        $(this).closest('.item').addClass('udu__with-focused-link');
                    }
                )
                .blur(
                    function() {
                        $(this).closest('.item').removeClass('udu__with-focused-link');
                    }
                );

            app.events.addListener(
                'udu_KEY-ESCAPE-PRESS',
                function() {
                    $('._ .dev-mode-warning > a').click()
                }
            );

            app.events.addListener(
                'udu_WINDOW-CLOSE',
                function() {
                    if (app.focuser._vars.$lastNormalFocusedItem) {
                        app.focuser._vars.$lastNormalFocusedItem.focus();
                    }
                }
            );

            $(document).keydown(
                function(e) {
                    if (Number(e.keyCode) === 9 && app.focuser._vars.stopNavigation) {
                        app.focuser.processAsPreventDefaultGroup();
                        e.preventDefault();
                    }
                }
            );

            var skipLinkTitle = 'Skip Navigation';
            if (app.getLang() === 'fr') {
                skipLinkTitle = 'Sauter la navigation';
            }

            skipLinkTitle += ' (Shift + X)';

            $('._').before('<a href="#" id="udu__skipLinksButton">' + skipLinkTitle + '</a>');

            $('#udu__skipLinksButton').focus(
                function() {
                    app.focuser.openSkipLinks()
                }
            )

        }
        $('a, button').each(
            function() {
                var $this = $(this);
                if ($this.data('udu-focus-init') === 'Y') {
                    return true;
                }
                $this.data('udu-focus-init', 'Y');
                $this.focus(
                    function() {
                        var $this = $(this).addClass('focus');
                        // working with top menu
                        if (!$this.data('tab-group')) {
                            app.focuser._vars.$lastNormalFocusedItem = $this;
                        }

                        if (app.focuser.asTopMenuItem($this)) {

                        } else if (app.focuser.asTabGroup($this)) {

                        }
                    }
                ).blur(
                    function() {
                        app.focuser._vars.stopNavigation = false;
                        if (app.focuser._vars.devMode) {
                            return false;
                        }
                        var $this = $(this).removeClass('focus');
                        if (app.focuser._vars.$parents) {
                            app.focuser._vars.$parents.removeClass('focus');
                            app.focuser._vars.$parents = null;
                        }

                    }
                )
            }
        );
    },
    processAsPreventDefaultGroup: function() {
        $('*[data-tab-group="' + app.focuser._vars.preventGroup + '"]:not(:focus)').focus();
    },
    asTabGroup: function($this) {

        var tabGroup = $this.data('tab-group');
        if (!tabGroup) {
            return false;
        }

        var $items = $('*[data-tab-group="' + tabGroup + '"]');
        if ($items.length < 3) {
            app.focuser._vars.stopNavigation = true;
            app.focuser._vars.preventGroup = tabGroup;
            return true;
        }

        $items.sort(function(x, y) {

            var $x = $(x);
            var $y = $(y);

            if (Number($x.data('tab-index')) < Number($y.data('tab-index'))) {
                return -1;
            }
            if (Number($x.data('tab-index')) > Number($y.data('tab-index'))) {
                return 1;
            }
            return 0;
        });

        var $tmp = null;
        var curIndex = Number($this.data('tab-index'));

        var wg = [null, $this, null];

        $items.each(
            function() {
                var $this = $(this);
                var idx = Number($this.data('tab-index'));
                if (idx > curIndex && !wg[2]) {
                    wg[2] = $this;
                }
                if (idx === curIndex && $tmp) {
                    wg[0] = $tmp;
                }
                $tmp = $this;
            }
        );

        if (!wg[2]) {
            wg[2] = $($items[0]);
        }

        if (!wg[0]) {
            wg[0] = $($items[$items.length - 1])
        }


        for (var i = 0; i < 3; i++) {
            wg[i].attr('tabindex', 1000 + i * 10);
        }

        return true;

    },
    asTopMenuItem: function($this) {
        var $menu = $this.closest('div.menu').find(' > ul');
        if ($menu.length === 0) {
            return false;
        }
        app.focuser._vars.$menu = $menu.addClass('focus');
        app.focuser._vars.$parents = $this.parents('li').addClass('focus');
        return true;
    }
};