
app.utils.table = {
	sortTable:function (th) {
		var $th = $(th);
		var thIndex = $th[0].cellIndex;
		var dir = 'asc';
		if ($th.hasClass('udu__sort-asc')) {
			dir = 'desc';
		} else if ($th.hasClass('udu__sort-desc')) {
			dir = '';
		}

		var $table = $th.closest('table');
		var $tbody = $table.find("> tbody");
		app.utils.effects.blinkIt($tbody);
		$table.find('th').removeClass('udu__sort-asc udu__sort-desc');
		if (dir) {
			$th.addClass('udu__sort-' + dir);
		}

		if (!$table.hasClass('_done-sort-data')) {
			$table.addClass('_done-sort-data');
			var pos = 1;
			$tbody.find(' > tr').each(
				function() {
					$(this).data('udu-tr-ini-pos', pos++);
				}
			)
		}

		// Disconnect the rows and get them as an array
		var $rows = $tbody.children().detach().get();

		// Sort it
		$rows.sort(function(left, right) {
			var $left = $(left);
			var $right = $(right);
			if (dir === '') {
				if (Number($left.data('udu-tr-ini-pos')) > Number($right.data('udu-tr-ini-pos'))){
					return 1;
				} else {
					return -1;
				}
			}

			// Get the text of the relevant td from left and right
			var $x = $left.children().eq(thIndex);
			var $y = $right.children().eq(thIndex);

			try {
				if ($x.length === 0 || Number($x.attr('colspan')) > 2 || $y[0].tagName === 'TH' || $x.hasClass('udu__td-no-sort')) {
					return 1;
				}
			} catch (e) {
				return -1;
			}

			try {
				if ($y.length === 0 || Number($y.attr('colspan')) > 2 || $x[0].tagName === 'TH' || $y.hasClass('udu__td-no-sort')) {
					return -1;
				}
			} catch (e) {
				return 1;
			}

			if ($x.data('udu-sort-ini-str')) {
				var xStr = $x.data('udu-sort-ini-str');
				var xDone = true;
			} else {
				xStr = String($x.html()).toLowerCase();
				xDone = false;
			}

			if ($y.data('udu-sort-ini-str')) {
				var yStr = $y.data('udu-sort-ini-str');
				var yDone = true;
			} else {
				yStr = String($y.html()).toLowerCase();
				yDone = false;
			}

			if (!xDone || !yDone) {
				if ($x.hasClass('money') || $x.hasClass('unit-price') || $x.hasClass('subtotal')) {
					if (!xDone) {
						var tmp = String(xStr).match(/([ ,\d]+\.\d+)/);
						if (tmp && tmp[1]) {
							xStr = Number(tmp[1].replace(/[ ,]+/g, ''));
						}
					}
					if (!yDone) {
						tmp = String(yStr).match(/([ ,\d]+\.\d+)/);
						if (tmp && tmp[1]) {
							yStr = Number(tmp[1].replace(/[ ,]+/g, ''));
						}
					}
				} else if ($x.hasClass('quantity') || $x.hasClass('num')) {
					if (!xDone) {
						xStr = Number($x.find('input[name=quantity]').val());
					}
					if (!yDone) {
						yStr = Number($y.find('input[name=quantity]').val());
					}
				} else if ($x.find('a').length > 0 || $y.find('a').length > 0 ) {
					if (!xDone) {
						xStr = $x.find('a').html();
					}
					if (!yDone) {
						yStr = $y.find('a').html();
					}
				}
				if (!xDone) {
					$x.data('udu-sort-ini-str', xStr);
				}
				if (!yDone) {
					$y.data('udu-sort-ini-str', yStr);
				}
			}

			if (dir === 'asc') {
				if (xStr > yStr) {
					return 1;
				} else {
					return -1;
				}
			} else {
				if (xStr < yStr) {
					return 1;
				} else {
					return -1;
				}
			}
		});
		$tbody.append($rows);
	},
	makeSortable: function($table) {
		var trCnt = $table.find('tr').length;
		var tdCnt = $table.find('td[colspan=5]').length;
		if (trCnt - tdCnt <= 2) {
			return true;
		}
		$table.addClass('udu__table-sortable')
			.find('th:not(.udu__not-sortable)').click(
			function() {
				app.utils.table.sortTable(this);
			}
		)
	}
};