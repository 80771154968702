"use strict";

if (!window.app) {
    window.app = {};
}

$(
    function() {
        app.balloon.init();
    }
);

app.balloon = {
    _vars: {

    },
	editBalloonContent: function(item) {
		var $this = $(item);
		var id = $this.data('udu-content-id');
		$this.data('udu-save-method', 'file');
		$this.addClass('udu__force-read-content');
		$this.addClass('_v2');
		admin.callStringEditor(
		    id,
            item,
            {

            }
        );
    },
    init: function() {
        if (this._vars._wasInit) {
            return false;
        }

        if (window.admin) {
            $(window).on(
                'keydown',
                function(e) {
                    if (e.code === 'F9') {
                        var $item = $('.adm__balloon-editor');
                        var id = $item.data('udu-content-id');
                        if (id) {
							app.balloon.editBalloonContent(
							    $item
                            );
                        }
                    }
                }
            )
        }

        $(document).click(
            function() {
                app.balloon.close();
            }
        );

        if (app.events) {
            app.events.addListener(
                'udu_KEY-ESCAPE-PRESS',
                function() {
                    app.balloon.close();
                }
            );
            app.events.addListener(
                'udu_WINDOW-CLOSE',
                function() {
                    if (app.balloon._vars.lastBalloonOptions && app.balloon._vars.lastBalloonOptions.isErrorMode) {
                        return false;
                    }
                    if (app.balloon._vars.noHideMode) {
                        return false;
                    }
                    app.balloon.close();
                }
            );
        }

        this._vars._wasInit = true;
        this.assignInfoToAll();
        this.wrapAll();
    },
    isBlocked: function() {
        return app.balloon._vars.isBlocked;
    },
    _block: function() {
        app.balloon._vars.isBlocked = true;
        clearTimeout(app.balloon._vars.isBlockedTimeId);
        app.balloon._vars.isBlockedTimeId = setTimeout(
            function() {
                app.balloon._vars.isBlocked = false;
            },
            300
        );
    },
    _assignTooltipster: function(selector, html, options) {
        var $items = $(selector);

		var trigger = ('ontouchstart' in window) ? 'click' : 'hover';
		//example 2

		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent) || (navigator.userAgent.toLowerCase().match(/(ipad|iphone)/))) {
			trigger = 'click';
		} else {
			trigger = 'hover';
		}

        $items.each(
            function() {
                var $this = $(this);
                if (!$this.hasClass('udu__balloon-init')) {
                    $this.addClass('udu__balloon-init');
                    var ballOptions = {
                        animation: 'grow',
                        delay: 200,
                        content: html,
                        contentAsHTML: true,
                        maxWidth: 400,
                        minWidth: 220,
                        trigger: trigger,
                        side: ['right', 'top', 'bottom', 'left'],
                        repositionOnScroll: true,
                        zIndex: 800,
                    };
                    if (options) {
                        for (var i in options) {
                            ballOptions[i] = options[i];
                        }
                    }

                    if ($this.closest('.wnd__window-container').length > 0) {
						ballOptions.zIndex = 1110000;
                    }

                    $this.tooltipster(ballOptions);
                    var $balloonIntance = $.tooltipster.instancesLatest()[0];
                    $this.data('udu-balloon-instance', $balloonIntance);
                    $this.data('udu-balloon-id', $balloonIntance.__namespace);
                } else {
                    $this.tooltipster('content', html);
                }

                app.balloon._addCssToBallon($this, options);

            }
        );
    },
    _addCssToBallon: function($item, options) {
        var $balItem = $('#' +  $item.data('udu-balloon-id'));
        $balItem.removeClass('udu__balloon-error udu__balloon-white udu__balloon-stable udu__balloon-z-top udu__balloon-z-top-2');
        if (options.isErrorMode) {
            $balItem.addClass('udu__balloon-error');
        } else if (options.isWhiteMode) {
            $balItem.addClass('udu__balloon-white');
        }

        if (options.isPopupMode) {
			$balItem.addClass('udu__balloon-popup-mode');
        }

        if (window.admin) {
			$balItem.addClass('udu__balloon-admin-mode');
        }

        if (options.balloonClassName) {
            $balItem.addClass(options.balloonClassName);
        }

        if (options.isStable) {
            $balItem.addClass('udu__balloon-stable');
            if (!$balItem.hasClass('_done')) {
                $balItem.addClass('_done').click(
                    function(e) {
                        app.balloon._vars.noHideMode = true;
                        e.stopPropagation();
                    }
                )
            }
        }

        if ($item.closest('#udu-div-window').length === 1) {
            $balItem.addClass('udu__balloon-z-top');
        } else if ($item.closest('.wnd__window-container').length > 0) {
			$balItem.addClass('udu__balloon-z-top-2');
        }
    },
    enable: function(selector) {
        $(selector).tooltipster('enable')
    },
    disable: function(selector) {
        $(selector).tooltipster('disable')
    },
    assign: function(selector, html, options) {
        options = options || {};
        if (options.replace) {
            for (var i in options.replace) {
                html = html.replace(i, options.replace[i]);
            }
        }
        var cssClass = 'udu__balloon-body';
        if (options.cssClass) {
            cssClass += ' ' + options.cssClass;
            delete options.cssClass;
        }
        var $item = $(selector);
        $item.data({
            'udu-balloon-html': html,
            'udu-balloon-options': options
        });

        if (options.__byCodeModeId && window.admin) {
			html += '<div class="adm__balloon-editor" onclick="app.balloon.editBalloonContent(this)" data-udu-content-id="' + options.__byCodeModeId + '">F9</div>';
        }

        this._assignTooltipster(
            $item,
            '<div class="' + cssClass + '"><div>' + html + '</div></div>',
            options
        );
    },
    assignInfo: function(selector, html, options) {
		options = options || {};
		if (String(html).indexOf('cfg__') === 0 && !options.__configDone) {
			options.__configDone = true;
			return app.balloon.assignInfoByCode(selector, html, options);
		}
        options = options || {};
        options.cssClass = 'udu_balloon-info';
        this.assign(selector, html, options);
    },
    assignInfoByCode: function(selector, html, options) {
        app.balloon._block();
        app.lang.getWord(
            html,
            function (msg) {
                options = options || {};
                options.__byCodeModeId = html;
                app.balloon.close();
                app.balloon.assignInfo(selector, msg, options);
            }
        );
        return $(selector);
    },
    assignInfoToAll: function() {
        $('*[data-udu-balloon-info]:not(.udu__balloon-init)')
            .each(
                function() {
                    var $this = $(this);
                    if ($this.closest('th').length > 0) {
                        app.balloon.assignInfo($this, $this.data('udu-balloon-info'), {side: ['top', 'right', 'bottom', 'left']});
                    } else {
                        app.balloon.assignInfo($this, $this.data('udu-balloon-info'));
                    }

                }
         );
    },
    assignToAll: function() {
        $('*[data-udu-balloon]:not(.udu__balloon-init)')
            .each(
                function() {
                    var $this = $(this);
                    app.balloon.assign($this, $this.data('udu-balloon'), {side:['top', 'bottom', 'right', 'left']});
                }
            );
    },
    showError: function (selector, html, options) {
		options = options || {};
        if (String(html).indexOf('cfg__') === 0 && !options.__configDone) {
			options.__configDone = true;
            return app.balloon.showErrorByCode(selector, html, options);
        }
        app.balloon._block();
        options = options || {};
        options.isErrorMode = true;
        $(selector).data('udu-stable-balloon', 'N');
        this.showInfo(selector, html, options);
    },
    showErrorByCode: function(selector, html, options) {
        app.balloon._block();
        app.lang.getWord(
            html,
            function (msg) {
                options = options || {};
                app.balloon.close();
                if (window.admin) {
                    msg += '<div class="adm__balloon-editor" onclick="app.balloon.editBalloonContent(this)" data-udu-content-id="' + html + '">...</div>';
                }
                app.balloon.showError(selector, msg, options);
            }
        );
        var $item = $(selector);
        if (app.focuser) {
            app.focuser.focus($item);
        }
        return $item;
    },
    repositionAll: function(withDelayed) {
        try {
			$('.udu__balloon-init.tooltipstered').tooltipster('reposition');
        } catch(e) {

        }
        if (withDelayed) {
            setTimeout(
                function() {
                    app.balloon.repositionAll();
                },
                350
            )
        }
    },
    showInfoByCode: function(selector, html, options) {
        app.balloon._block();
        options = options || {};
        if ($('.tooltipster-base:visible').length > 0) {
            return false;
        }
        var ldKey = 'udu-dispBalloons';
        if (app.storeId) {
            ldKey += ':' + app.storeId;
        }
        var lData = app.getLData(ldKey, true);
        if (!lData) {
            lData = {};
        }
        var tmp = html + '-' + app.getLang();
        if(lData[tmp] === 'Y' && !options.multipleMode) {
            return false;
        }
        lData[tmp] = 'Y';
        app.setLData(ldKey, lData, true);
        app.lang.getWord(
            html,
            function (msg) {
                if (!options.hasOwnProperty('noScroll')) {
                    options.noScroll = true;
                }
				if (window.admin) {
					msg += '<div class="adm__balloon-editor" onclick="app.balloon.editBalloonContent(this)" data-udu-content-id="' + html + '">...</div>';
				}
                options.singleMode = true;
                app.balloon.showInfo(selector, msg, options);
            }
        )
    },
    showInfo: function(selector, html, options) {
		options = options || {};
		if (String(html).indexOf('cfg__') === 0 && !options.__configDone) {
			options.__configDone = true;
			return app.balloon.showInfoByCode(selector, html, options);
		}
        app.balloon._block();
        var that = this;
        options = options || {};
        setTimeout(
            function () {
                if (options.singleMode && $('.tooltipster-base:visible').length > 0) {
                    return false;
                }
                options.cssClass = 'udu_balloon-info';
                that.show(
                    selector,
                    html,
                    options
                )
            },
            options.withDelay ? options.withDelay : 10
        );
    },
    show: function(selector, html, options) {
        app.balloon._block();
        this._vars.lastBalloonOptions = options;
        var $item = $(selector);

        options = options || {};

        if (options.onlyIfVisible) {
            var $bal = app.balloon.getBalloonForItem($item);
            if ($bal.length === 0 || $bal.height() < 30) {
                return false;
            }
        }

        options.trigger = 'custom';

        this.close();

        if ($item.length === 0 || !html) {
            return false;
        }

        $item = $($item[0]);

        app.balloon.assign(
            $item,
            html,
            options
        );

        $item.tooltipster('open');

        var $balItem = $('#' +  $item.data('udu-balloon-id'));

        app.balloon._addCssToBallon($item, options);

        if (!options.noScroll && !options.noDelay && $item.closest('#udu-div-window').length === 0 && $item.closest('.wnd__window-container').length === 0) {
            $balItem.addClass('udu__balloon-hidden');
            var $topBalloon = $('.tooltipster-top');
            var $top = $('._ > .top');
            if ($topBalloon.length > 0 || $top.css('position') === 'fixed') {
                var yDelta = +($topBalloon.height() || 0) +($top.height() || 10);
            } else {
                yDelta = 10;
            }

            yDelta += 50;

            if (app.utils && app.utils.isItemVisible && !app.utils.isItemVisible($item, 100 + yDelta)) {
                this.close();
                app.balloonScrollTopCorrection = app.balloonScrollTopCorrection || 0;
                var scrollTo = $item.offset().top - app.balloonScrollTopCorrection - yDelta;
                $('body, html')
                    .stop()
                    .animate(
                        {
                            scrollTop: Math.max(scrollTo, 0)
                        },
                        800,
                        function() {
                            $balItem.removeClass('udu__balloon-hidden');
                            $item.tooltipster('open');
                        }
                    );
            } else {
                $balItem.removeClass('udu__balloon-hidden');
            }
        }

        app.balloon._vars.lastOpenedSelector = $item;


        if (!app.balloonScrollTopCorrection) {
            app.balloonScrollTopCorrection = 0;
        }
    },
    close: function(forceClose) {
        if (!forceClose && app.balloon.isBlocked()) {
            return this;
        }
        if (!this._vars.lastOpenedSelector) {
            return this;
        }
        if (this._vars.adminEditMode) {
            return this;
        }
        var instances = $.tooltipster.instances();
        $.each(instances, function(i, instance){
            instance.close();
        });
        this._vars.lastOpenedSelector = null;
        return this;
    },
    wrapAll: function() {
        $('*[data-udu-balloon-wrap-code]:not(.udu__balloon-init)')
            .each(
                function() {
                    var $this = $(this);
                    var code = $this.data('udu-balloon-wrap-code');
                    if (code) {
                        var options = {};
                        if ($this.hasClass('udu__no-change-width')) {
                            options.doNotChangeWidth = true;
                        }
                        app.balloon.wrapInputWithHelpByCode($this, code, options);
                    }

                }
            );
    },
    wrapInputWithHelpByCode: function(selector, html, options) {
        app.lang.getWord(
            html,
            function (msg) {
                options = options || {};
                options.cssClass = options.cssClass || '';
                options.cssClass += ' ' + html;
                app.balloon.close();
                app.balloon.wrapInputWithHelp(selector, msg, options);
            }
        );
        return $(selector);
    },
    wrapInputWithHelp: function(selector, helpHtml, options) {
        options = options || {};
        var $items = $(selector);
        $items.each(
            function() {
                var $this = $(this);
                if (!$this.data('help-wrapper-id')) {
                    var guid = app.guid();
                    var id = 'help-' + app.guid();
                    if (!$this.attr('id')) {
                        $this.attr('id',  'inp-' + app.guid());
                    }
                    $this.data('help-wrapper-id', id);
                    var addCss = '';
                    if (options.doNotChangeWidth) {
                        addCss = ' udu__no-change-width';
                    }
                    var html = '<div class="udu__help-input-wrapper" id="' + id + '"><div class="udu__input-place' + addCss + '"></div><div class="udu__input-help udu__unselectable"><span>?</span></div></div>';
                    $this.after(html);
                    var $hlp = $('#' + id);
                    $hlp.find('.udu__input-place').append($this);

                    var boxSizing = $this.css('box-sizing');
                    if (boxSizing !== 'border-box') {
                        var wDelta = 0;
                        var props = ['marginLeft', 'marginRight', 'paddingLeft', 'paddingRight'];
                        for (var i = 0; i < props.length; i++) {
                            wDelta += parseInt($this.css(props[i]));
                        }
                        if (!options.doNotChangeWidth) {
                            $('head').append('<style id="style-' + $this.attr('id') + '">#' + $this.attr('id') + '{width: calc(100% - ' + wDelta + 'px) !important}');
                        }
                    }
                    $hlp.addClass('_done');
                    setTimeout(
                        function() {
                            $hlp.find('.udu__input-help').addClass('_done');
                            app.utils.effects.showIt($hlp.find('.udu__input-help span'));
                        },
                        300
                    );

                } else {
                    id = $this.data('help-wrapper-id');
                    $hlp = $('#' + id);
                }

                var $span = $hlp.find('.udu__input-help span');
                if ($span.closest('#udu-div-window').length === 1) {
                    options.zIndex = 75000;
                }
                app.balloon.assignInfo($span, helpHtml, options);
				//$span.on('touchstart', function() {$span.mouseover()});
            }
        )
    },
    closeById: function(id) {
        var instances = $.tooltipster.instances();
        $.each(instances, function(i, instance){
            if (instance.__namespace === id) {
                instance.close();
            }

        });
    },
    getBalloonForItem: function($item) {
        var balId = $item.data('udu-balloon-id');
        return $('#' +  balId);
    },
    assignPopup: function(selector, html, options) {
        options = options || {};
        options.isWhiteMode = true;
        options.isPopupMode = true;
        options.side = ['bottom', 'top'];
        options.minWidth = 90;
        app.balloon.assignStableBalloon(selector, html, options);
    },
    assignStableBalloon: function (selector, html, options) {
        options = options || {};
        options.noScroll = true;
        options.isStable = true;
        var $item = $(selector);
        $item.data({
            'udu-balloon-html': html,
            'udu-balloon-options': options
        });
        $item.data('udu-stable-balloon', 'Y');
        if ($item.data('udu-ballon-stable-done') !== 'Y') {
            $item.data('udu-ballon-stable-done', 'Y');
            $item.mouseleave (
                function () {

                    var $item = $(this);
                    var balId = $item.data('udu-balloon-id');
                    var $balItem = app.balloon.getBalloonForItem($item);

                    if ($balItem.length === 1) {
                        $balItem.mousemove(
                            function() {
                                var timerId = $item.data('udu-close-balloon-id');
                                if (timerId) {
                                    clearTimeout(timerId);
                                }
                                $item.addClass('udu__balloon-is-open');
                            }
                        ).mouseleave(
                            function() {
                                var timerId = setTimeout(
                                    function() {
                                        if (!app.balloon._vars.debugMode && !app.balloon._vars.noHideMode) {
                                            app.balloon.closeById(balId);
											$item.removeClass('udu__balloon-is-open');
                                        }
                                    },
                                    300
                                );
                                $item.data('udu-close-balloon-id', timerId);
                            }
                        )
                    }

                    var timerId = setTimeout(
                        function() {
                            if (!app.balloon._vars.debugMode && !app.balloon._vars.noHideMode) {
                                app.balloon.closeById(balId);
								$item.removeClass('udu__balloon-is-open');
                            }
                        },
                        300
                    );

                    $item.data('udu-close-balloon-id', timerId);
                }
            );
            $item.mousemove (
                function() {
                    var $this = $(this);
                    if ($this.data('udu-stable-balloon') === 'Y') {
                        app.balloon._onMouseMoveStatbleItem($(this));
						$this.addClass('udu__balloon-is-open');
                    }
                }
            );
        }

        if (options.showNow) {
            clearTimeout(app.balloon._vars.showNowTimeOutId);
			app.balloon._vars.showNowTimeOutId = setTimeout(
                function() {
                    $item.mousemove();
                },
                200
            )
        }
    },
    _onMouseMoveStatbleItem: function($item) {
        var timerId = $item.data('udu-close-balloon-id');
        if (timerId) {
            clearTimeout(timerId);
        }
        var balId = $item.data('udu-balloon-id');
        if (balId) {
            var $balItem = $('#' +  balId);
            if ($balItem.length === 1 && $balItem.height() > 20) {
                return;
            }
        }

        var options = $item.data('udu-balloon-options');
        options.onlyIfVisible = false;
		app.balloon.close(true);
        app.balloon.show($item, $item.data('udu-balloon-html'), options);
    },
    saveContent: function(selector, html) {
        $(selector).data('udu-balloon-html', html);
    },
    assignAjaxBalloon: function(selector, params) {
        params = params || {};

        var $div = $(selector);

        if ($div.length === 0) {
		    return this;
        }

        if (!params.css) {
            params.css = '';
        }

        if (!params.code) {
		    app.error('There is not Code for assignAjaxBalloon')
        }

        params.id = app.guid();

		$div.on(
			'mouseenter',
			function() {
				var $this = $(this);
				if (!$this.hasClass('_done')) {
					$this.addClass('_done');

					var html = '<div id="' + params.id + '"><div class="udu__loading-v1" style="min-width: 140px"></div></div>';

					if (params.beforeHtml) {
					    html = '<div class="udu__before-html">' + params.beforeHtml + '</div>' + html;
                    }

                    html = '<div class="udu__ajax-balloon-wrapper ' + params.css + '">' + html + '</div>';

					app.balloon.assignStableBalloon (
						'.udu__my-budgets-link',
						html,
						{side:['bottom'], isWhiteMode: true, balloonClassName: 'udu__under-confirm-mode'}
					);

					app.getInfo(
					    params.code,
                        function(html) {
					       html = $('#' + params.id).html(html).closest('.udu__ajax-balloon-wrapper').html();
					       app.balloon.repositionAll();
					       app.balloon.saveContent(selector, '<div class="udu__ajax-balloon-wrapper ' + params.css + '">' + html + '</div>');
                        }
                    )
				}
			}
		);
    }
};