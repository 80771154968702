app.color = {
    _var: {

    },
    init: function() {
        try {
            this.schemaColor(this.schemaColor());
        } catch (e) {

        }
    },
    getCssClassColor: function(className) {
        if (!className) {
            className = 'schema-color';
        }
        var $div = $('<div class="' + className + ' hidden"></div>').appendTo('._');
        var color = $div.css('color');
        $div.remove();
        return color;
    },
    schemaColor: function (color) {
        if (arguments.length === 0) {
            return this.getCssClassColor();
        } else {
            if (!color){
                color = '#ffffff';
            }
            if (color.indexOf(',') > 0) {
                color = this.rgbToHex(color);
            }
            var body = $("body").get(0);
            body.style.setProperty("--schema-color", color);
            body.style.setProperty("--schema-color-dark-50", this.changeColor(color, -50));
            body.style.setProperty("--schema-color-dark-30", this.changeColor(color, -30));
            body.style.setProperty("--schema-color-dark-20", this.changeColor(color, -20));
        }
    },
    setCssColorVar: function(varName, color) {
        $("body").get(0).style.setProperty(varName, color);
    },
    numToHex: function(c) {
        if (!c) {
            c = 0;
        }
        var hex = Number(c).toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    },
    rgbToHex: function(r, g, b) {
        if (arguments.length !== 3) {
            var tmp = String(r).match(/\d+/g);
            if (tmp && tmp.length >= 3) {
                r = tmp[0];
                g = tmp[1];
                b = tmp[2];
            } else {
              r = g = b = 255;
            }
        }
        return "#" + this.numToHex(r) + this.numToHex(g) + this.numToHex(b);
    },
    changeColor: function(color, amt) {
        var num = parseInt(color.replace('#', ''), 16),
            R = (num >> 16) + amt,
            B = (num >> 8 & 0x00FF) + amt,
            G = (num & 0x0000FF) + amt;
        return '#' + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
    }
};