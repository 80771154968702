/**
 * Calls alert box
 *
 * @param {string} html
 * @param {function} onOk
 * @param {{}} params
 * @returns {void}
 */
app.alert = function(html, onOk, params) {
	app.showWindow(html, 'ALERT', params);
	app.onOk  = onOk;
	app.onYes = null;
	app.onNo  = null;
};

/**
 * Calls confirm box
 *
 * @param {string} html
 * @param {function} onYes
 * @param {function}onNo
 * @returns {void}
 */
app.confirm = function(html, onYes, onNo, params) {
	app.showWindow(html, 'CONFIRM', params);
	app.onOk  = null;
	app.onYes = onYes;
	app.onNo  = onNo;
};

/**
 * Displays custom popup window for (alert, confirm, dialog)
 *
 * @param {string} html
 * @param {string} winType
 * @param {{}} params
 * @returns {void}
 */
app.showWindow = function(html, winType, params) {
	if (!params) {
		params = {};
	}
	app._hideWindowInProgress = false;

	if (!params.noBlockWindow) {
		app.blockWindow(true);
	}
	var $div = $('#udu-div-window');
	if ($div.length  === 0) {
		$('.udu-window__wrapper').remove();
		var appendToSelector = $('._').length > 0 ? '._' : 'body';
		if (params.appendToSelector) {
			appendToSelector = params.appendToSelector;
		}
		$('<div class="udu-window__wrapper"><div id="udu-div-window">' +
			'<button type="button" data-tab-group="udu-wnd" data-tab-index="10" onclick="app.onYes = null;app.hideWindow({btnClose: true})" class="close custom-button"></button>' +
			'<div class="body"></div><div class="buttons"></div>' +
			'</div></div>')
			.appendTo(appendToSelector)
			.click(
				function (e) {
					if (app.balloon && !app.balloon._vars.noHideMode) {
						app.balloon.close();
					}
					e.stopPropagation();
				}
			);

		if (app.wnd && app.wnd.openWindowExists()) {
			$('.udu-window__wrapper').addClass('wnd__most-top');
		} else {
			$('.udu-window__wrapper').removeClass('wnd__most-top');
		}

		$div = $('#udu-div-window');
		$div.click(
			function(e) {
				var $this = $(this);
				if ($this.find('.cart-form form').length > 0) {
					app.balloon.close();
				}
				e.stopPropagation();
			}
		);
		$(document).keyup(
			function(e) {
				if (e.keyCode == 27) {
					app.onYes = null;
					app.hideWindow();
				}
			}
		);
	}

	$div.removeClass();

	var $body = $div.find('.body').html(html);
	var h = $body.height();

	if (h < 20) {
		$body.css('text-align', 'center');
	} else {
		$body.css('text-align', 'left');
	}

	if (!params.rightButtonHTML) {
		params.rightButtonHTML = '';
	}

	if (!params.leftButtonHTML) {
		params.leftButtonHTML = '';
	}

	app._winType = winType;

	if (winType === 'ALERT') {
		$div.addClass('alert');
		if (!params.okTitle) {
			params.okTitle = app.lang.selectWord('Ok', 'D\'accord');
		}
		$div.find('.buttons')
			.html(params.leftButtonHTML + '<button type="button" data-tab-group="udu-wnd" data-tab-index="1" class="udu-ok custom-button" onclick="app.hideWindow()">' + params.okTitle + '</button>' + params.rightButtonHTML);
	} else if (winType === 'DIALOG') {
		$div.addClass('alert');
		$div.find('.buttons')
			.html(params.leftButtonHTML + '<button type="button" class="udu-ok custom-button" onclick="if (app.onOk) {app.onOk()} else {app.hideWindow()}">' + (params.okTitle ? params.okTitle : 'Process') + '</button>' + params.rightButtonHTML);
	} else if (winType === 'CONFIRM') {
		var langCode = app.getLang();
		if (!params.yesTitle) {
			params.yesTitle = (langCode === 'en' ? 'Yes' : 'Oui');
		}
		if (!params.noTitle) {
			params.noTitle = (langCode === 'en' ? 'No' : 'Non');
		}
		$div.addClass('confirm');
		$div.find('.buttons')
			.html('<button type="button" data-tab-group="udu-wnd" data-tab-index="1" class="udu-ok custom-button" onclick="app.onNo = null;app.hideWindow()">'
				+ params.yesTitle
				+'</button><button data-tab-group="udu-wnd" data-tab-index="2" type="button" class="udu-no custom-button" onclick="app.onYes = null;app.hideWindow()">'
				+ params.noTitle + '</button>' + params.rightButtonHTML);
	} else {
		$div.find('.buttons').html('');
	}
	if (params.cssClass) {
		$div.addClass(params.cssClass);
	}

	app._vars.lastCalledWindowsParams = params;

	setTimeout(
		function() {
			$('.udu-window__wrapper').addClass('udu-window__visible');
			if (app.focuser) {
				app.focuser.focus($('.buttons button.udu-ok'));
			}
		},
		30
	);

	app.popup.setMaxHeight();
};

/**
 * Blocks screen with div
 *
 * @param {bool} doBlock
 * @param {bool} showPreloader
 * @param {function} callBack
 * @param {string} textMessage
 * @returns {void}
 */
app.blockWindow = function(doBlock, showPreloader, callBack, textMessage) {

	var $divBlock = $("#div-block");
	var $divWait = $divBlock.find('.block-wait');

	if (textMessage === 'P_W') {
		textMessage = app.lang.selectWord('Please wait...', "Patientez s'il-vous-plait...");
	}
	if (doBlock && showPreloader && $divWait.length === 1 && $divWait.offset().top > 0 && $divBlock.hasClass('udu__wnd-block-shown') && !textMessage) {
		if (callBack) {
			callBack();
		}
		return true;
	}
	var allJson = app.getLData('get-all-json', true);
	var addBlockCss = '';
	if (allJson && allJson.udu__blockWindowStyle) {
		addBlockCss = allJson.udu__blockWindowStyle;
	} else if (app._vars.udu__blockWindowStyle) {
		addBlockCss = app._vars.udu__blockWindowStyle;
	}

	if ($divBlock.length === 0) {
		$("<div id=\"div-block\"><div class=\"block-wait\"></div></div>")
			.appendTo('body')
			.click(
				function (e) {
					e.stopPropagation();
				}
			);
		$divBlock = $("#div-block");
		$divWait = $divBlock.find('.block-wait');
	} else {
		$divWait.hide();
	}

	if (app.wnd && app.wnd.openWindowExists()) {
		$divBlock.addClass('wnd__most-top');
	} else {
		$divBlock.removeClass('wnd__most-top');
	}

	$divBlock.addClass(addBlockCss);

	$divBlock.find('.udu__wait-message').remove();
	var blockOpacity = 0.6;
	if (textMessage) {
		$divBlock.append('<div class="udu__wait-message udu__shine-text">' + textMessage + '</div>');
		blockOpacity += 0.2;
	}

	if (showPreloader) {
		$divBlock.addClass('udu__with-preloader');
		try {
			var opts = {
				lines: 15,
				length: 31,
				width: 6,
				radius: 46,
				corners: 0.9,
				rotate: 78,
				direction: 1,
				color: '#fff',
				speed: 1.2,
				trail: 100,
				shadow: false,
				hwaccel: false,
				className: 'spinner',
				zIndex: 2e9,
				top: '100px',
				left: '100px'
			};
			var spinner = new Spinner(opts).spin($divWait[0]);
			if (!app.waitBlockZIndex) {
				$divBlock.css('z-index', 999);
			} else {
				$divBlock.css('z-index', app.waitBlockZIndex);
			}
			app.balloon.close();
		} catch(e) {

		}
		setTimeout(
			function() {
				$divWait.fadeIn(1000);
			},
			100
		)
	} else {
		$divBlock.removeClass('udu__with-preloader');
	}
	if (doBlock) {
		$divBlock.css('display', 'flex')
			.addClass('udu__wnd-block-shown')
			.fadeTo(0,0)
			.stop()
			.fadeTo(
				400,
				blockOpacity,
				function() {
					if (callBack) {
						callBack();
					}
				}
			)
	} else {
		$divBlock.css('display', 'flex')
			.removeClass('udu__wnd-block-shown')
			.stop()
			.fadeTo(
				200,
				0,
				function() {
					$("#div-block").hide().css('z-index', 9999);
					$divWait.html('');
					if (callBack) {
						callBack();
					}
				}
			)
	}
};

/**
 * Hides custom popup window
 *
 * @returns {void}
 */
app.hideWindow = function(params) {
	params = params || {};

	app._hideWindowInProgress = true;

	if (app._winType === 'DIALOG') {
		app.onOk = null;
	}

	if (app.onYes && app._vars.lastCalledWindowsParams.noUnblockWindow) {
		// do nothing
	} else {
		if (!app.onOk) {
			app.blockWindow(false);
		}
	}


	if (app.onOk) {
		var fRes = app.onOk(params);
		if (fRes === false) {
			return false;
		} else {
			app.blockWindow(false);
		}
	}
	if (app.onYes) {
		app.onYes();
	}
	if (app.onNo) {
		app.onNo();
	}
	$('.udu-window__wrapper').removeClass('udu-window__visible');
	app.events.trigger('udu_WINDOW-CLOSE', {});
};

/**
 * Calls dialog box - with ability to enter any inner HTML
 *
 * @param {string} html
 * @param {function} onOk
 * @param {{}} params
 * @returns {void}
 */
app.dialog = function(html, onOk, params) {
	app.showWindow(html, 'DIALOG', params);
	app.onOk  = onOk;
	app.onYes = null;
	app.onNo  = null;
};

app.errorMsg = function(html, onOk, params) {
	params = params || {};
	params.cssClass = 'udu__redError';
	app.alertMsg(html, onOk, params);
};

/**
 * Calls alert box - by message code
 *
 * @param {string} html
 * @param {function} onOk
 * @param {{}} params
 * @returns {void}
 */
app.alertMsg = function(html, onOk, params) {
	if (!params) {
		params = {};
	}
	var msgs = [];
	if (typeof html === 'object') {
		msgs = html;
		html = msgs[0];
	} else {
		msgs[0] = html;
	}
	var btnId = html + '__buttonCaption_' + app.getLang();
	app.onOk  = onOk;
	app.onYes = null;
	app.onNo  = null;
	app.blockWindow(true);
	app.initializeMessages(
		msgs.join(',') + ',' + btnId,
		function() {
			var msg = app.getMsg(msgs[0]);
			if (!msg || msg === msgs[0]) {
				for (var i = 1; i < msgs.length; i++) {
					msg = app.getMsg(msgs[i]);
					if (msg && msg !== msgs[i]) {
						break;
					}
				}
			}
			if (params && params.replace) {
				for (var i in params.replace) {
					msg = msg.replace(i, params.replace[i]);
				}
			}
			if (window.admin) {
				if (!params.rightButtonHTML) {
					params.rightButtonHTML = '';
				}
				params.rightButtonHTML += '<a class="wnd-edit-link">Edit</a>';
			}

			if (params.doBeforeShow) {
				msg = params.doBeforeShow(msg);
			}
			if (app.getMsg(btnId) && app.getMsg(btnId) !== btnId) {
				params.okTitle = app.getMsg(btnId);
			}
			app.alert(msg, onOk, params);
			if (window.admin) {
				$('.wnd-edit-link').click(
					function(e) {
						e.preventDefault();
						admin.editMsg(html, 'alert');
						if (window.mailer) {
							mailer.closeWindow();
						}
					}
				)
			}
		}
	);
};

app.confirmDangerMsg = function(html, onYes, onNo, params) {
	params = params || {};
	params.cssClass = 'udu__redError';
	app.confirmMsg(html, onYes, onNo, params);
}
