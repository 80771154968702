"use strict";
app.orders = {
    _vars: {

    },
    clearOrderData: function(opts) {
        opts = opts ? opts : {};
        app.getInfo(
            'get-urls',
            {json: true},
            function(data){
                if (opts.orderId) {
                    if (opts.orderId === app.getLData('udu__clearedOrderId')) {
                        return ;
                    }
                    app.setLData('udu__clearedOrderId', opts.orderId);
                }
                if (opts.PurchaseOnAccount === 'Y') {
                    var url = data['PurchaseOnAccount'];
                    if (url) {
                        $.post(
                            url,
                            {
                                accountNumber: '',
                                clearData: 1
                            }
                        )
                    }
                }
                if (opts.clearCostCenterData === 'Y') {
                    var url = data['CostCenter'];
                    if (url) {
                        $.post(
                            url,
                            {
                                accountNumber: '',
                                clearData: 1
                            }
                        )
                    }
                }
            }
        )
    }
};