app.utils.effects = {
	scrollTop: function(pos, delay) {
		if (delay) {
			setTimeout(
				function() {
					$('body, html').stop().animate({scrollTop: pos});
				},
				delay
			)
		} else {
			$('body, html').stop().animate({scrollTop: pos});
		}

	},
	blinkIt: function(selector, html) {
		var $item = $(selector);
		app.utils.effects._setCSS($item, 'udu-blink');
		if (html) {
			setTimeout(
				function() {
					$item.html(html);
				},
				30
			);
		}
		setTimeout(
			function() {
				$item.removeClass('udu-blink');
			},
			400
		)
	},
	shakeIt: function(selector) {
		var $item = $(selector);
		app.utils.effects._setCSS($item, 'udu__shake');
		return $item;
	},
	setLoading: function(selector, setAsLoading) {
		return $(selector).addClass('udu__loading-v1');
	},
	unsetLoading: function(selector, setAsLoading) {
		return $(selector).removeClass('udu__loading-v1');
	},
	pulseIt: function(selector) {
		var $item = $(selector);
		app.utils.effects._setCSS($item, 'udu__pulse');
		return $item;
	},
	showIt: function(selector, html, removeCSSAfterFinish, removeHiddenOnStart) {
		var $item = $(selector);
		if (html) {
			$item.html(html).addClass('udu__show-from-dot');
		}
		app.utils.effects._setCSS($item, 'udu__show-from-dot');
		if (removeHiddenOnStart) {
			setTimeout(
				function() {
					$item.removeClass('hidden');
				},
				40
			)
		}
		if (removeCSSAfterFinish) {
			setTimeout(
				function() {
					$item.removeClass('udu__show-from-dot');
				},
				1000
			)
		}
	},
	_setCSS: function($item, css) {
		$item.removeClass(css);
		setTimeout(
			function() {
				$item.addClass(css);
			},
			10
		)
	}
};