app.topMenu = {
    _vars: {
        
    },
    onResize: function() {
        var $div = app.topMenu._vars.$wrapper;
        var w = Number($div.width());
        var $lis = $div.find(' > ul > li');
        var subExists = $div.find('.with-children').length > 0;
        if (!subExists) {
            $div.addClass('udu__no-sub-menu');
        } else {
            $div.removeClass('udu__no-sub-menu');
        }
        var sumW = 0;
        var curW = 0;
        var hiddenCount = 0;
        $lis.each(
            function() {
                var $this = $(this);
                var needHide = false;
                if (hiddenCount > 0) {
                    $this.addClass('rm-hidden');
                    hiddenCount++;
                    return;
                }

                sumW += Number($this.outerWidth());

                if (Number(sumW) > w - 12) {
                    needHide = true;
                } else {
                    curW  += Number($this.outerWidth());
                }
                if (needHide) {
                    $this.addClass('rm-hidden');
                    hiddenCount++;
                } else {
                    $this.removeClass('rm-hidden');
                }
            }
        );

        var $hum = app.topMenu._vars.$hum;
        if (hiddenCount > 0) {
            $hum.html(hiddenCount).addClass('hum-visible').removeClass('hum-changed');
            $('._').addClass('udu_hum-with-hidden-items');
        } else {
            $hum.html('').removeClass('hum-visible');
            $('._').removeClass('udu_hum-with-hidden-items');
            app.topMenu.removePopup();
        }
    },
    correctSize: function($ul) {
        var h = 80;
        $ul.find('> li').each(
            function () {
                h += $(this).outerHeight()
            }
        );

        var wH = Math.max($(window).height() - $('._  >.top').outerHeight(), 20);
        var cnt = Math.min(Math.ceil(h / wH), 6);

        if (cnt > 1) {
            var css = 'ul-mega-1 column-multiple column-count-' + cnt;
            if (cnt >= 6) {
                css += ' column-count-max';
            }
            $ul.data('col-cnt', cnt).css('column-count', cnt)[0].className = css;
        } else {
            $ul.data('col-cnt', 1).css('column-count', 1)[0].className = 'ul-mega-1';
        }

    },
    correctPosition: function($parent, $ul) {
        var parWidth = $parent.outerWidth();
        var ulWidth = $ul.outerWidth();
        if (ulWidth < parWidth) {
            $ul.css('left', 0);
        } else {
            var d = (ulWidth - parWidth) / 2;
            $ul.css('left', -d);
            var left = parseInt($ul.css('left'));
            d = $(window).width() - 30 - ($ul.offset().left + $ul.outerWidth());
            if (d < 0) {
                left = left + d;
                $ul.css('left', left);
            }

            if ($ul.offset().left < 0) {
                $ul.css('left', parseInt($ul.css('left')) + 15 - $ul.offset().left);
            }
        }

    },
    monitorMenu: function(params) {
		//var $catWidePopups = $('.udu__wide-category-popup #ucust-prod-cat .ul-mega-1, .udu__top-category-wide-mode .ul-mega-1');
		var $catWidePopups = $('.udu__wide-category-popup #ucust-prod-cat .ul-mega-1, .udu__top-category-wide-mode .ul-mega-1, .udu__top-category-image-mode .ul-mega-1');
		if ($catWidePopups.length > 0) {
			$catWidePopups.each(
				function() {
					var $catWidePopup = $(this);
					app.topMenu.correctWidePopup($catWidePopup);
				}
			);
		} else {
			$catWidePopups = null;
		}
        $('.menu .ul-mega-1:not(.my-cart-popup):not(.udu__mega-intact)').closest('li').each(
            function() {
                var $this = $(this);
                var $ul = $this.find('.ul-mega-1');
                $ul.find('> li').each(
                    function() {
                        var $li = $(this);
                        if ($li.hasClass('with-children') && $li.find('.ul-mega-holder').length === 0) {
                            $li.append('<div class="ul-mega-holder"></div>');
                            $li.find('.ul-mega-holder').append($li.find('ul.ul-mega-2'));
                            $li.mouseenter(
                                function() {
                                    var wH = $(window).height();
                                    var wW = $(window).width();
                                    var $this = $li.find('.ul-mega-2');

                                    var h = $this.outerHeight();
                                    var hDif = wH - ($li[0].getBoundingClientRect().y + h);

                                    if (hDif > 20) {
                                        $this.css('margin-top', 0);
                                    } else {
                                        $this.css('margin-top', hDif - 10);
                                    }

                                    var $wrp = $this.closest('.ul-mega-holder')
                                        .removeClass('l-orient');

                                    var w = $this.outerWidth();
                                    var wDif = wW - ($li.offset().left + $li.outerWidth() + w);

                                    if (wDif > 20) {
                                        $wrp.removeClass('l-orient');
                                        $this.css('margin-left', 0);
                                    } else {
                                        $wrp.addClass('l-orient');
                                        $this.css('margin-left', -w);
                                    }
                                }
                            )
                        }
                    }
                );
                // reset settings
                $ul.hide();
                setTimeout(
                    function() {
                        $ul.show();
                        app.topMenu.correctSize($ul);
                        app.topMenu.correctPosition($this, $ul);
                    },
                    10
                );
            }
        )
    },
    correctWidePopup: function($catWidePopup) {
		$catWidePopup.addClass('udu__mega-intact udu__scroller-1');
		var d = ($(window).width() - $catWidePopup.width()) / 2;

		$catWidePopup.css('left', d + (parseInt($catWidePopup.css('left')) - $catWidePopup.offset().left));

		var l = parseInt($catWidePopup.css('left'));
		var box = $catWidePopup[0].getBoundingClientRect();
		var w = $(window).width();
		var r = w - box.right;
		var d2 = box.left - r;

		$catWidePopup.css('left', l - d2 / 2);

		var $parent = $catWidePopup.closest('li');

		var t = $parent[0].getBoundingClientRect().bottom + 20;
		var h = $(window).height();
		d = Math.max(h - t, 60);

		$catWidePopup.css('max-height', d + 'px');
    },
    init: function(params) {
        params = params || {};
        //var $catWidePopups = $('.udu__wide-category-popup #ucust-prod-cat .ul-mega-1, .udu__top-category-wide-mode .ul-mega-1');
		var $catWidePopups = $('.udu__wide-category-popup #ucust-prod-cat .ul-mega-1, .udu__top-category-wide-mode .ul-mega-1, .udu__top-category-image-mode .ul-mega-1');
        if ($catWidePopups.length > 0) {
			$catWidePopups.each(
			    function() {
			        var $catWidePopup = $(this);
					$catWidePopup.addClass('udu__mega-intact udu__scroller-1');
                    app.topMenu.correctWidePopup($catWidePopup);
                }
            );
        } else {
			$catWidePopups = null;
        }

        app.topMenu._vars.iniParams = params;
        app.topMenu.monitorMenu(params);
        $(window).resize(
            function() {
                $('.udu__u-menu > ul:not(._devMode)').removeClass('focus');
                app.topMenu.monitorMenu(params);
                if ($catWidePopups) {
					$catWidePopups.each(
						function() {
							var $catWidePopup = $(this);
							app.topMenu.correctWidePopup($catWidePopup);
						}
					);
                }
            }
        );

        var $cartPopup = $('ul.ul-mega-1.my-cart-popup');
        if ($cartPopup.length > 0) {
            $cartPopup.closest('li').click(
                function(e) {
                    e.stopPropagation();
                }
            );
            $(document).click (
                function() {
                    app.showMenuPopup(null);
                }
            );
        }
    }
};