$(
	function() {
		app.utils.accord.init();

		setTimeout(
			function() {
				$('.attr-search-form-block.shown').find('.udu__acc-header._acc-done').addClass('udu__ass-ini-done').click();
			},
			320
		)
	}
);
app.utils.accord = {
	_vars: {

	},
	init: function() {
		$('.udu__acc-header:not(._acc-done)')
			.addClass('_acc-done')
			.click(
				function() {
					var $this = $(this);
					var $parent = $this.parent('div');
					if ($this.data('body-id')) {
						var $body = $('#' + $this.data('body-id'))
							.css('transition', 'all 0.3s ease-in-out 0s');
					} else {
						$body = $parent.find('> .udu__acc-body')
							.css('transition', 'all 0.3s ease-in-out 0s');
					}

					$this.toggleClass('shown');
					$body.stop();

					clearTimeout($body.data('acc-timer-id'));

					app.events.trigger(
						'udu-ACCORD-CHANGED',
						{
							$item: $this,
							$parent: $parent,
							$body: $body
						}
					);

					var h = $body.find(' > div').height() + 15;

					if ($this.hasClass('shown')) {
						$body.css('max-height', h + 'px');
						$parent.addClass('shown');
						var timerId = setTimeout(
							function() {
								$body.css('max-height', '100000px');
								app.utils.accord.sync();
							},
							310
						);
						$body.data('acc-timer-id', timerId);
					} else {
						$parent.removeClass('shown');
						$body.css('transition', 'all 0s');
						$body.css('max-height', h + 'px');
						timerId = setTimeout(
							function() {
								$body.css('transition', 'all 0.2s ease-in-out 0s');
								$body.css('max-height', 0);
								app.utils.accord.sync();
							},
							10
						);
						$body.data('acc-timer-id', timerId);
					}
				}
			);
	},
	sync: function() {
		$('.udu__accord-is-ready').removeClass('udu__accord-is-ready');
		$('.udu__acc-box.shown > .udu__acc-body').addClass('udu__accord-is-ready');
	}
};