/**
 * Initiate ACBL functionality
 */
app.initACBL = function() {
    var $acbl = $('.aclb-letters');
    $acbl.find('button, > span').click(
        function(e) {
            var css = $(this)[0].className;
            var fontSize = String(css).match(/aclb-s-(\d)/)[1];
            app._setAcblFontSize(fontSize, $acbl);
            e.preventDefault();
        }
    ).keypress(
        function(e) {
            var css = $(this)[0].className;
            var fontSize = String(css).match(/aclb-s-(\d)/)[1];
            app._setAcblFontSize(fontSize, $acbl);
            e.preventDefault();
        }
    );
    var bodyCss = $('body')[0].className;
    var savedFont = String(bodyCss).match(/_fts-(\d)/);
    if (savedFont && savedFont[1]) {
        savedFont = savedFont[1];
    } else {
        savedFont = app.getLData('aclb-font-size');
        if (!savedFont) {
            savedFont = 1;
        }
    }
    app._setAcblFontSize(savedFont, $acbl);
};

/**
 * Sets necessary ACBL font to body and saved it
 *
 * @param {number} fontSize
 * @private
 */
app._setAcblFontSize = function (fontSize, $acbl) {
    $acbl.find('button').prop('aria-pressed', false);
    $acbl.find('.selected').removeClass('selected');
    $acbl.find('.aclb-s-' + fontSize)
        .addClass('selected')
        .prop('aria-pressed', true);
    app.setLData('aclb-font-size', fontSize);
    app.createCookie('_fts', fontSize);
    var $body = $('body');
    var cur = $body[0].className.match(/_fts-(\d)/);
    if (cur && cur[1]) {
        $body.removeClass('_fts-' + cur[1]);
    }
    $body.addClass('_fts-' + fontSize);
};