app.popup = {
	setMaxHeight: function() {
		var $wnd = $('#udu-div-window');
		var h = Math.max($(window).height() - 110, 130);
		var padBottom = parseInt($wnd.css('padding-bottom'));
		var padTop = parseInt($wnd.css('padding-top'));
		$wnd.css('max-height', h);
		$wnd.find('.height-correctable').css('max-height', Math.max(h - padBottom - padTop, 90));
	},
	initMsgPopups: function () {
		var items = {
			_Button_Checkout_Status: {
				sel: '.checkout-link a.button.checkout, .checkout-link a.button.checkout-cart, .checkout-link a.button.fa-checkout',
				html: '_Button_Checkout_HTML',
				jKey: '_Button_Checkout_Status_' + app.getLang()
			}
		};
		var dispArr = [];
		for (var i in items) {
			var wasDone = app.getLData(i, true);
			if (wasDone === 'Y') {
				continue;
			}
			var $item = $(items[i].sel);
			if ($item.length > 0) {
				dispArr.push({
					lKey: i,
					info: items[i]
				})
			}
		}

		if (dispArr.length > 0) {
			app.getAllJSON(
				function(data) {
					for (var i = 0; i < dispArr.length; i++) {
						var needDisp = data[dispArr[i].info.jKey];
						if (needDisp === 'Y') {
							var $item = $(dispArr[i].info.sel);
							if ($item.hasClass('_done')) {
								continue;
							}
							$item.data('udu-popup-info', dispArr[i]).addClass('_done');
							$item.click(
								function(e) {
									var $this = $(this);
									var popInfo = $this.data('udu-popup-info');
									var msgKey = popInfo.info.html;
									var wasDone = app.getLData(popInfo.lKey, true);
									if (wasDone !== 'Y') {
										app.alertMsg(
											msgKey,
											function(params) {
												if (!params || !params.btnClose) {
													app.setLData(popInfo.lKey, 'Y', true);
													$this[0].click();
												}
											},
											{
												cssClass: 'fullMode centerPopup'
											}
										);
										e.preventDefault();
									}

									return true;
								}
							);
						}
					}
				}
			)
		}
	}
};