app.lang = {
	switch: function() {
		var lang = app.getLang();
		var url = String(document.location).split('#')[0];
		if (lang === 'en') {
			url = app.lang.getUrl(url, 'fr');
		} else {
			url = app.lang.getUrl(url, 'en');
		}
		document.location = url;
	},
	getUrl: function(url, lang) {
		if (lang === 'en') {
			url = url.replace(/(\/fr\/|\/fr$)/, '/en/');
		} else {
			url = url.replace(/(\/en\/|\/en$)/, '/fr/');
		}
		if (!url.match(/(\/en\/|\/en$|\/fr\/|\/fr$)/)) {
			if (url.slice(-1) != '/') {
				url += '/';
			}
			url += lang + '/';
		}

		return url;
	},
	isEn: function() {
		return app.getLang() === 'en';
	},
	selectWord: function(enWrd, frWrd) {
		if (app.getLang() === 'en') {
			return enWrd;
		} else {
			return frWrd;
		}
	},
	getWord: function(msg, callBack, forceReload) {
		if (app.messages[msg] && !forceReload) {
			if (callBack) {
				callBack(app.messages[msg]);
			}
			return app.messages[msg];
		}
		app.initializeMessages(
			msg,
			function() {
				if (callBack) {
					var wrd = app.messages[msg];
					if (!wrd) {
						wrd = '';
					}
					callBack(wrd);
				}
			}
		)
	}
};