$(
	function() {
		app.dataLayer.init();
	}
)
window.dataLayer = window.dataLayer || [];

app.dataLayer = {
	_vars: {
		v: '1.0.1'
	},
	init: function() {
		app.dataLayer.addLoginEvent();
	},
	checkGtag: function() {
		if (!window.gtag) {
			app.utils.scripts.initWnd(
				function() {
					app.wnd.error(
						'Cannot find <b>window.gtag</b> object. Please visit <a target="_blank" href="https://support.google.com/google-ads/answer/12985952">https://support.google.com/google-ads/answer/12985952</a> to get more Info about it.',
						null
					)
				}
			)
		}
	},
	addLoginEvent: function() {
		if (!window.gtag) {
			return false;
		}
		if (!app._usrLogged) {
			app.setLData('_gtagLoggedEvtAdded', 'N');
			return false;
		} else if (app._usrLogged && app.getLData('_gtagLoggedEvtAdded') != 'Y') {
			app.dataLayer.gtag(
				'login',
				{
					method: "Google"
				}
			);
			app.setLData('_gtagLoggedEvtAdded', 'Y');
			return true;
		}
		return false;
	},
	gtag: function(evtName, data) {
		if (!window.gtag) {
			return false;
		}
		gtag("event", evtName, data);
		app.dataLayer.addToLog(evtName, data);
	},
	addToLog: function (evtName, data) {
		var hist = app.dataLayer.history();
		hist.push({evt: evtName, data: data, tm: new Date().toISOString()});
		app.setLData('_gtagHist', hist, true)
	},
	history: function() {
		var hist = app.getLData('_gtagHist', true);
		if (!Array.isArray(hist)) {
			hist = [];
		}
		return hist;
	}
};