"use strict";
app.accessibilty = {
    _vars: {
        ids: {

        }
    },
    init: function () {
        app.accessibilty.makeUniqIDS();
        app.accessibilty.setAriaLabels();
        app.accessibilty.fixImgAlt();
        app.accessibilty.fixLefMenuExpand();
        setTimeout(
            function() {
                app.accessibilty.fixNivoSlider();
            },
            2000
        );

        if (app.wca) {
			app.wca.init();
        }
    },
    fixNivoSlider: function() {
        $('.nivo-controlNav a')
            .attr('href', '#')
            .attr('aria-label', app.lang.selectWord('Click here to Navigate to this item.', 'Cliquez ici pour accéder à cet article'));

        $('.nivo-prevNav').attr('aria-label', app.lang.selectWord('Previous', 'Précédent')).attr('href', '#');
        $('.nivo-nextNav').attr('aria-label', app.lang.selectWord('Next', 'Suivant')).attr('href', '#');
    },
    fixLefMenuExpand: function() {
        $('.li-block a .node').each(
            function() {
                var $this = $(this);
                var $a = $this.closest('a');
                if ($this.hasClass('opened')) {
                    $a.attr('aria-expanded', true);
                } else {
                    $a.attr('aria-expanded', false);
                }
            }
        );
    },

    table2Div: function(sel) {
        if (!sel) {
            sel = '._ .udu__add-fields-place';
        }
        var $sel = $(sel);

		$('fieldset').find('input[name=password], input[name=passwordConfirmation]')
            .closest('tr')
            .addClass('udu__tr2div-flex-row');

        $sel.each(
            function() {
                var $this = $(this);

                $this.find('table').addClass('udu__table2div-v2');
                $this.find('tr').addClass('udu__tr2div-v2');
                $this.find('td').addClass('udu__td2div-v2');

                var html = $this.html();

                html = html.replace(/<tbody>/g, '');
                html = html.replace(/<\/tbody>/g, '');
                html = html.replace(/<thead>/g, '');
                html = html.replace(/<\/thead>/g, '');
                html = html.replace(/<table /g, '<div ');
                html = html.replace(/<\/table>/g, '</div>');

				html = html.replace(/<tr /g, '<div ');
				html = html.replace(/<\/tr>/g, '</div>');

				html = html.replace(/<td /g, '<div ');
				html = html.replace(/<\/td>/g, '</div>');

				$this.html(html);
				$this.addClass('_t2d__done');

            }
        )
    },

    fixImgAlt: function() {
        $('img').each(
            function() {
                var $this = $(this);
                var alt = $this.attr('alt');
                if (alt) {
                    var newAlt = app.utils.str.clear(alt);
                    if (newAlt !== alt) {
                        $this.attr('alt', newAlt);
                    }
                }
                /*
                var title = $this.attr('title');
                if (alt) {
                    var newTitle = app.utils.str.clear(title);
                    if (newTitle !== title) {
                        $this.attr('title', newTitle);
                    }
                }
                */
            }
        )
    },
    makeUniqIDS: function() {
        var $accessStyle = $('style#udu__access-css');
        if ($accessStyle.length === 1) {
            var idsRemove = $accessStyle.html().split('{')[0];
            if (idsRemove) {
                $(idsRemove).remove();
            }
        }

        var $items = $('[id]');
        var fixCount = 0;
        $items.each(
            function() {
                var $this = $(this);
                if ($this.data('udu__uniq-id-process') === 'Y') {
                    return true;
                }
                $this.data('udu__uniq-id-process', 'Y');
                var id = $this.attr('id');
                var iniId = id;
                var pos = 1;
                while (app.accessibilty._vars.ids[id]) {
                    id = $this.attr('id') + '__' + pos;
                    pos++;
                }
                app.accessibilty._vars.ids[id] = true;
                if (iniId !== id) {
                    $this.attr('data-udu-ini-id', iniId);
					$this.attr('id', id);
                }

                fixCount++;
            }
        );

        return fixCount;
    },

    setAriaLabels: function() {
        $('.discount-code input[name=code]').attr('aria-label', app.lang.selectWord('Enter Discount Code...', 'Entrez le code de réduction'));
        $('form tr, form .udu__tr2div-v2')
            .each(
                function() {
                    var $tr = $(this);
                    var $label = $tr.find('td.label label, .udu__td2div-v2.label label');
                    if ($label.length === 0) {
                        $label = $tr.find('td.control label, .udu__td2div-v2.control label');
                    }
                    if ($label.length > 0) {
                        var $inp = $tr.find('input, select, textarea');
                        if (!$inp.attr('aria-labelledby')) {
                            if ($label.find('span').length > 0) {
                                var fldName = $label.find('span').html();
                            } else {
                                fldName = $label.html();
                            }

                            if (app.getLang() === 'en') {
                                $inp.attr('aria-label', 'Enter ' + fldName + ' here');
                            } else {
                                $inp.attr('aria-label', 'Entrez ' + fldName + ' ici');
                            }
                        }

                        if (!$inp.attr('id')) {
                            var nm = $inp.attr('name');
                            if (nm) {
                                $inp.attr('id', nm);
                            }
                        }

                        $label.attr('for', $inp.attr('id'));
                    }

                }
            )
    }
};