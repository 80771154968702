app.events = {
	_vars: {

	},
	initBaseEvents: function() {
		if (app.events._vars.baseEventsCopied) {
			return this;
		}
		app.events._vars.baseEventsCopied = true;
		if (app._vars.baseEvents) {
			for (var i in app._vars.baseEvents) {
				app.events.addListener(app._vars.baseEvents[i], i);
			}
		}

		// new approach now we can add events with the same name
		if (app._vars.baseEventsArrV2) {
			for (i = 0; i < app._vars.baseEventsArrV2.length; i++) {
				var tmp = app._vars.baseEventsArrV2[i];
				app.events.addListener(tmp.fun, tmp.nm);
			}
		}
	},
	addListener: function(fun, eventType) {
		if (!app._vars.__uduListeners) {
			app._vars.__uduListeners = [];
		}
		if (typeof fun === 'function') {
			var info = {
				f: fun
			};
			if (eventType) {
				info.t = eventType
			}
			app._vars.__uduListeners.push(info);
		} else if (arguments.length === 2 && typeof eventType === 'function') {
			app._vars.__uduListeners.push({
				t: fun,
				f: eventType
			});
		} else if (arguments.length === 2 && typeof fun === 'function') {
			app._vars.__uduListeners.push({
				f: fun,
				t: eventType
			});
		}
	},
	trigger: function(eventName, evData) {
		app.events.initBaseEvents();
		var res = {data: evData, eventName: eventName};
		res.toString = function() {
			return this.eventName;
		};
		if (!app._vars.__uduListeners) {
			app._vars.__uduListeners = [];
		}
		app._vars.__uduListeners.forEach(
			function(info) {
				if (!info.t || info.t === eventName) {
					info.f(res, res);
				}
			}
		);
		if (!res) {
			return {};
		}
		return res;
	}
};