"use strict";

$(
    function() {
        app.hotKeys.init();
    }
);

app.hotKeys = {
    _vars: {
        inpArr: {
            'INPUT': true,
            'SELECT': true,
            'TEXTAREA': true,
        }
    },
    stop: function(e) {
        e.stopPropagation();
        e.preventDefault();
    },
    init: function() {
        $(document).keydown(
            function(e) {
                var $el = $('*:focus');
                var $letters = $('.aclb-letters');
                if ($el.length > 0) {
                    var tagName = $el[0].tagName;
                    if (app.hotKeys._vars.inpArr[tagName]) {
                        return true;
                    }
                }
                if (!e.shiftKey) {
                    if (e.code === 'Space') {
                        var $node = $el.find('> .node');
                        if ($node.length > 0) {
                            $node.mousedown();
                        }

                        e.stopPropagation();
                        e.preventDefault();
                    }
                } else {
                    var navArr = {
                        'KeyC': {
                            href: $('a.cart, a.my-cart-link').attr('href'),
                            name: app.lang.selectWord('the Cart', 'panier')
                        },
                        'KeyH': {
                            href: '---',
                            name: app.lang.selectWord('Home Page', 'Page d\'accueil'),
                            fun: function () {
                                app.url.goHome()
                            }
                        },
                        'KeyE': {
                            href: app._usrLogged ? '---' : null,
                            name: app.lang.selectWord('Log Out Page', 'Page de déconnexion'),
                            fun: function () {
                                app.url.goLogOut()
                            }
                        },
                        'KeyL': {
                            href: !app._usrLogged ? '---' : null,
                            name: app.lang.selectWord('Log In Page', 'Page de connexion'),
                            fun: function () {
                                app.url.goLogIn()
                            }
                        },
                        'KeyA': {
                            href: app._usrLogged ? '---' : null,
                            name: app.lang.selectWord('User Account', 'Compte d\'utilisateur'),
                            fun: function () {
                                app.url.goLogIn()
                            }
                        },
                        'KeyS': {
                            href: '---',
                            name: app.lang.selectWord('Search Page', 'Page de recherche'),
                            fun: function () {
                                app.url.goToSearch()
                            }
                        }
                    };

                    if (e.code === 'KeyZ') {
                        $("div.product-view div.zoom a, ._ div.product-view .image-inner a, div.product-view div.zoom-advanced a").click();
                        app.hotKeys.stop(e);
                        return true;
                    } else if (e.code === 'KeyX' && $letters.length > 0) {
                        app.focuser.openSkipLinks();
                    } else if (e.code === 'KeyI') {
                        $('input, select, textarea').attr('tabindex', 100).focus();
                        app.hotKeys.stop(e);
                        return true;
                    } else if (e.code.indexOf('Digit') === 0) {
                        var num = Number(e.code.replace('Digit', ''));
                        if (num) {
                            var $links = $('.pagination-menu a');
                            if ($links[num]) {
                                app.url.goTo($links[num].href, true);
                                app.hotKeys.stop(e);
                                return true;
                            }
                        }
                    } else if (e.code === 'KeyW' && window.admin) {
                        admin.enableDisableEditMode();
                        app.hotKeys.stop(e);
                        return true;
                    }

                    for (var i in navArr) {
                        if (e.code === i) {
                            if (navArr[i].href) {
                                app.confirmMsg(
                                    '_Do_you_Really_Navigate',
                                    function() {
                                        if (navArr[i].fun) {
                                            navArr[i].fun();
                                        } else {
                                            app.url.goTo(navArr[i].href);
                                        }
                                    },
                                    null,
                                    {
                                        replace: {
                                            '%NAME%': navArr[i].name
                                        }
                                    }
                                )
                            } else {
                                app.alertMsg(
                                    '_You_Cannot_Navigate',
                                    null,
                                    {
                                        replace: {
                                            '%NAME%': navArr[i].name
                                        }
                                    }
                                );
                            }
                            break;
                        }
                    }
                }
            }
        )
    }

};
