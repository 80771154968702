app.json = {
	parse: function(str, base2url) {
		var obj = {};
		try {
			obj = JSON.parse(str);
		} catch (e) {

		}

		if (base2url) {
			obj = app.json.base2url(obj);
		}

		return obj;
	},
	stringify: function(obj, url2base) {
		if (url2base) {
			obj = app.json.url2base(app.utils.unlinkArr(obj));
		}
		return JSON.stringify(obj);
	},
	fixArr: function() {
		var data = app.getLData('udu-all-json-local-storage');
		var res = {'{publicDir}': data['publicURL']};
		var baseUrl = data['baseURL'];
		if (baseUrl) {
			res['{baseUrl}'] = baseUrl;
			res['%7BbaseUrl%7D'] = baseUrl;
		}
		return res;
	},
	base2url: function (html) {
		if (!html) {
			return '';
		}
		if (typeof html === 'function') {
			return html;
		}
		if (typeof html === 'object') {
			for (var y in html) {
				html[y] = app.json.base2url(html[y]);
			}
			return html;
		}

		var arr = app.json.fixArr();
		var chr = String.fromCharCode(1)
		for (var i in arr) {
			var s = arr[i];
			if (String(s).split('/').length > 2) {
				var r = new RegExp(i, 'g');
				html = html.replace(r, s + chr);
			}
		}

		html = html.replace(new RegExp(chr + '/', 'g'), chr);
		html = html.replace(new RegExp('/' + chr , 'g'), chr);
		html = html.replace(new RegExp(chr , 'g'), '/');

		return html;
	},
	url2base: function(html) {
		if (!html) {
			return '';
		}
		if (typeof html === 'function') {
			return html;
		}
		if (typeof html === 'object') {
			for (var y in html) {
				html[y] = app.json.url2base(html[y]);
			}
			return html;
		}
		var arr = app.json.fixArr();
		for (var i in arr) {
			var s = arr[i];
			if (String(s).split('/').length > 2) {
				var r = new RegExp(s, 'g');
				html = html.replace(r, i);
			}
		}

		html = html.replace(/{baseUrl}\//g, '{baseUrl}');

		return html;
	}
};