"use strict";
$(
    function () {
        app.uMenu.init();
    }
);
app.uMenu = {
    _vars: {

    },
    init: function() {
        var $uDiv = $('.udu__u-menu:not(_done)');
        if ($uDiv.length === 0) {
            return false;
        }

        $uDiv.addClass('_done');

        app.events.addListener(
            'udu_U-MENU-RESIZED',
            function(e) {
                app.uMenu.sync(e.data.$div);
            }
        );

        try {
            new ResizeObserver(
                function() {
                    app.events.trigger(
                        'udu_U-MENU-RESIZED',
                        {
                            $div: $uDiv
                        }
                    );
                }
            ).observe($uDiv[0]);
        } catch(e) {
            setInterval(
                function() {
                    app.events.trigger(
                        'udu_U-MENU-RESIZED',
                        {
                            $div: $uDiv
                        }
                    );
                },
                2000
            )
        }

        var $ul = $uDiv.find('> ul');
        $ul.mouseenter(
            function() {
                $ul.addClass('udu__hover');
                clearTimeout(app.uMenu._vars.mouseLeaveTimerId);
            }
        ).mouseleave(
            function() {
                clearTimeout(app.uMenu._vars.mouseLeaveTimerId);
                app.uMenu._vars.mouseLeaveTimerId = setTimeout(
                    function() {
                        $ul.removeClass('udu__hover');
                    },
                    500
                );
            }
        );

        $ul.append('<li class="udu__u-menu-counter with-children"><div class="udu__u-count-place"></div><div class="delimiter udu__wide-delim"></div></li>');
        app.uMenu.sync($uDiv);
        for (var i = 1; i < 10; i++) {
			setTimeout(
				function() {
					if (app.uMenu.needSynch()) {
						app.uMenu.sync($uDiv, {silent: true});
					}
				},
				i * 500
			);
        }
    },
    needSynch: function() {
        var $ul = app.uMenu._vars.$ul;
        if (!$ul) {
            return false;
        }
        var isChanged = false;
        var pos = 0;
		$ul.find('> li:not(.udu__u-menu-counter)').each(
		    function() {
				var $this = $(this);
				pos++;
				if (pos > 5) {
				    return false;
                }
				if (Math.round($this.width()) !== Math.round($this.data('udu-li-width'))) {
					isChanged = true;
					return false;
                }
            }
        );
		var topVis = -9999;
		if (!isChanged) {
			$ul.find('> li:not(.udu__u-menu-counter)').each(
				function() {
					var $this = $(this);
					var off = $this.offset();
					if (topVis === -9999) {
						topVis = off.top;
					}
					$this.data('udu-li-width', Math.round($this.width()));
					if (Number(off.top) > Number(topVis) + 5 && !$this.hasClass('udu__u-menu-item-not-visible')) {
						isChanged = true;
						return false;
					}
				}
			);
        }

		return isChanged;
    },
    sync: function($div, params) {
        params = params || {};
        if (!$div) {
            $div = $('.udu__u-menu');
        }

        var $ul = $div.find('> ul');
        var notVisCount = 0;
        var topVis = -9999;
        var liHtml = '';
		app.uMenu._vars.$ul = $ul;
        $ul.find('> li:not(.udu__u-menu-counter)').each(
            function() {
                var $this = $(this);
                var off = $this.offset();
                if (topVis === -9999) {
                    topVis = off.top;
                }
                $this.data('udu-li-width', Math.round($this.width()));
                if (Number(off.top) > Number(topVis) + 5) {
                    $this.addClass('udu__u-menu-item-not-visible');
                    liHtml += '<li id="' + $this.attr('id') + '" class="' + $this[0].className + '">' + $this.html() + '</li>';
                    notVisCount++;
                } else {
                    $this.removeClass('udu__u-menu-item-not-visible');
                }
            }
        );

        if (notVisCount > 0) {
            if (!$div.hasClass('udu__u-menu-with-hidden-items')) {
                app.uMenu._vars.notVisCountTimerId = setTimeout(
                    function() {
                        $div.addClass('udu__u-menu-with-hidden-items');
                    },
                    100
                )
            }
        } else {
            clearTimeout(app.uMenu._vars.notVisCountTimerId);
            $div.removeClass('udu__u-menu-with-hidden-items');
        }

        var $counter = $div.find('.udu__u-menu-counter');
        var prevCount = Number($counter.find('.udu__u-count-place').html());

        $counter.find('.udu__u-count-place').html(notVisCount);

        if (notVisCount > 0 && prevCount !== notVisCount) {
            $counter.find('.ul-mega-1').remove();
            var html = '<ul class="ul-mega-1 top-menu-cats viewed">';

            html += liHtml;

            html += '</ul>';
            $counter.append(
                html
            );

            $counter.find('.udu__u-menu-item-not-visible').removeClass('udu__u-menu-item-not-visible');

            if (!params.silent && !app.uMenu._vars.asSilent) {
                app.uMenu._vars.asSilent = true;
                app.utils.effects.showIt($counter.find('.udu__u-count-place'));
                setTimeout(
                    function() {
                        app.uMenu._vars.asSilent = false;
                    },
                    900
                )
            }

        }

        if (app.topMenu) {
            app.topMenu.monitorMenu(app.topMenu._vars.iniParams);
        }
    }
};
