"use strict";

app.countDown = {
	_vars: {},
	set: function (sel, callBack, time) {
		time = time || 3;

		var $item = $(sel);

		$item.addClass('udu__count-down');

		if ($item.css('position') === 'static') {
			$item.css('position', 'relative')
		}

		var $cd = $item.find('.udu__circle-counter');
		if ($cd.length > 0) {
			clearTimeout($cd.data('udu-time-id'));
			$cd.remove();
		}
		$item.append(
			'<div class="udu__circle-counter"><svg><circle class="udu__gray" r="18" cx="20" cy="20"></circle><circle class="udu__acitve" r="18" cx="20" cy="20"></circle></svg><span class="udu__count-down-point">' + time + '</span></div>'
		);

		$cd = $item.find('.udu__circle-counter')
			.click(
				function(e) {
					clearTimeout($cd.data('udu-time-id'));
					clearInterval(cdInterval);
					$cd.remove();
					$item.removeClass('udu__count-down');
					e.stopPropagation();
				}
			);

		$cd.find('svg circle.udu__acitve').css('animation', 'udu__countdown-keyframes ' + time + 's linear infinite forwards');

		var $place = $item.find('.udu__circle-counter .udu__count-down-point');

		var cdInterval = setInterval(
			function() {
				time--;
				$place.html(time);
			},
			1000
		);

		if (!$cd.data('udu-time-id')) {
			var cdTimer = setTimeout(
				function() {
					$item.removeClass('udu__count-down');
					$item.find('.udu__circle-counter').remove();
					if (callBack) {
						callBack($item);
					}
				},
				time * 1000
			);

			$cd.data('udu-time-id', cdTimer);
		}


	}
};
